import { toast } from "react-hot-toast";
import axios from "axios";
import { refreshMe } from "./me";

const initialState = {
  loading: false
};

const fortnoxReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "FORTNOX/LOADING/TRUE":
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case "FORTNOX/LOADING/FALSE":
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case "FORTNOX/SET/STATUS/CONNECTED":
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case "FORTNOX/SET/STATUS/DISCONNECTED":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const connectFortnox = () => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: "FORTNOX/LOADING/TRUE" });

      // Get auth URL from backend
      const response = await axios.get('/fortnox/auth-url');
      const { authUrl } = response.data;

      // Open Fortnox OAuth page in a new window
      const width = 600;
      const height = 700;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2;
      const features = `width=${width},height=${height},left=${left},top=${top}`;

      window.open(authUrl, "Fortnox Authorization", features);

      // Poll for connection status
      const checkConnection = setInterval(async () => {
        try {
          const user = await axios.get('/users/me');
          if (user.data.selected_organization?.fortnox_enabled) {
            clearInterval(checkConnection);
            dispatch({ type: "FORTNOX/SET/STATUS/CONNECTED" });
            dispatch(refreshMe());
            toast.success("Successfully connected to Fortnox");
          }
        } catch (error) {
          console.error("Error checking Fortnox connection:", error);
        }
      }, 2000);

      // Stop polling after 2 minutes
      setTimeout(() => {
        clearInterval(checkConnection);
        dispatch({ type: "FORTNOX/LOADING/FALSE" });
      }, 120000);

    } catch (err) {
      console.error(err);
      dispatch({ type: "FORTNOX/LOADING/FALSE" });
      toast.error(`Could not connect to Fortnox. Reason: ${String(err)}`);
    }
  };
};

export const handleFortnoxCallback = (code: string) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: "FORTNOX/LOADING/TRUE" });

      // Exchange code for token with authenticated request
      await axios.post('/fortnox/exchange-token', { code });

      dispatch({ type: "FORTNOX/SET/STATUS/CONNECTED" });
      dispatch(refreshMe());
      toast.success("Successfully connected to Fortnox");

    } catch (err: any) {
      console.error(err);
      dispatch({ type: "FORTNOX/LOADING/FALSE" });
      
      // Handle expired code case
      if (err.response?.status === 400) {
        toast.error("Authorization code expired. Please try connecting again.");
        // Trigger a new connection attempt
        dispatch(connectFortnox());
      } else {
        toast.error(`Could not connect to Fortnox. Reason: ${String(err)}`);
      }
    }
  };
};

export const disconnectFortnox = () => {
  return async (dispatch: any) => {
    try {
      await axios.post('/fortnox/disconnect');
      dispatch({ type: "FORTNOX/SET/STATUS/DISCONNECTED" });
      dispatch(refreshMe());
      toast.success("Successfully disconnected from Fortnox");
    } catch (err) {
      console.error(err);
      toast.error(`Could not disconnect from Fortnox. Reason: ${String(err)}`);
    }
  };
};

export default fortnoxReducer;
