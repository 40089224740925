export const getProxiedImageUrl = (imageUrl: string): string => {
  try {
    // Get JWT token from user in localStorage
    const user = localStorage.getItem('user');
    const jwt = user ? JSON.parse(user).jwt : '';
    const baseUrl = process.env.REACT_APP_baseUrl;
    const token = jwt.replace('Bearer ', '');

    // Handle Strapi media URLs and other URLs through our proxy
    return `${baseUrl}/cs/proxy/image?url=${encodeURIComponent(imageUrl)}&token=${encodeURIComponent(token)}`;
  } catch (error) {
    console.error('Error creating proxied URL:', error);
    return imageUrl;
  }
};
