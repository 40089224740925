import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  connectTrello,
  disconnectTrello,
} from "../../../redux/reducerSlices/trello";
import {
  connectUpwork,
  disconnectUpwork,
} from "../../../redux/reducerSlices/upwork";
import {
  connectFortnox,
  disconnectFortnox,
} from "../../../redux/reducerSlices/fortnox";

const IntegrationsTab = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state);

  return (
    <Box style={{ marginTop: 0 }}>
      <Typography variant="h5">
        <FormattedMessage id="general.Integrations" defaultMessage="Integrations" />
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={2}
      >
        {/* Trello Integration */}
        <Grid item sm={12}>
          <Typography variant="h6">
            <FormattedMessage id="general.Trello" defaultMessage="Trello" />
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
            <FormattedMessage
              id="general.Trello-Integration-Description"
              defaultMessage="Connect your Trello account to sync tasks and projects"
            />
          </Typography>
          {Boolean(user.trello_token) ? (
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                dispatch(disconnectTrello());
              }}
            >
              <FormattedMessage
                id="general.Disconnect-Trello"
                defaultMessage="Disconnect Trello"
              />
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                dispatch(connectTrello());
              }}
            >
              <FormattedMessage
                id="general.Connect-Trello"
                defaultMessage="Connect Trello"
              />
            </Button>
          )}
        </Grid>

        {/* Only show Upwork section if user has access */}
        {user.upwork_access && (
          <>
            <Grid item sm={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>

            {/* Upwork Integration */}
            <Grid item sm={12}>
              <Typography variant="h6">
                <FormattedMessage id="general.Upwork" defaultMessage="Upwork" />
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                <FormattedMessage
                  id="general.Upwork-Integration-Description"
                  defaultMessage="Connect your Upwork account to import timesheets and expenses"
                />
              </Typography>
              {Boolean(user.upwork_token) ? (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    dispatch(disconnectUpwork());
                  }}
                >
                  <FormattedMessage
                    id="general.Disconnect-Upwork"
                    defaultMessage="Disconnect Upwork"
                  />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    dispatch(connectUpwork());
                  }}
                >
                  <FormattedMessage
                    id="general.Connect-Upwork"
                    defaultMessage="Connect Upwork"
                  />
                </Button>
              )}
            </Grid>
          </>
        )}

        {/* Only show Fortnox section if user has access */}
        {user.fortnox_access && (
          <>
            <Grid item sm={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>

            {/* Fortnox Integration */}
            <Grid item sm={12}>
              <Typography variant="h6">
                <FormattedMessage id="general.Fortnox" defaultMessage="Fortnox" />
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                <FormattedMessage
                  id="general.Fortnox-Integration-Description"
                  defaultMessage="Connect your Fortnox account to sync invoices and clients"
                />
              </Typography>
              {Boolean(user.fortnox_access_token) ? (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    dispatch(disconnectFortnox());
                  }}
                >
                  <FormattedMessage
                    id="general.Disconnect-Fortnox"
                    defaultMessage="Disconnect Fortnox"
                  />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    dispatch(connectFortnox());
                  }}
                >
                  <FormattedMessage
                    id="general.Connect-Fortnox"
                    defaultMessage="Connect Fortnox"
                  />
                </Button>
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Divider sx={{ mt: 2 }} />
    </Box>
  );
};

export default IntegrationsTab;
