import React from 'react';
import { Box, Paper, Typography, List, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from 'react-intl';
import { Conversation } from '../../types/conversation';
import { ConversationItem } from './ConversationItem';

interface ConversationListProps {
  conversations: Conversation[];
  selectedConversation: Conversation | null;
  isCreatingChat: boolean;
  onSelectConversation: (conversation: Conversation) => void;
  onStartNewChat: () => void;
  onEditTitle: (id: number, title: string) => void;
  onDeleteConversation: (id: number) => void;
}

export const ConversationList: React.FC<ConversationListProps> = ({
  conversations,
  selectedConversation,
  isCreatingChat,
  onSelectConversation,
  onStartNewChat,
  onEditTitle,
  onDeleteConversation
}) => {
  const intl = useIntl();

  return (
    <Paper 
      sx={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'sticky',
        top: 16,
      }}
    >
      <Box sx={{ 
        p: 2,
        borderBottom: 1,
        borderColor: 'divider',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexShrink: 0
      }}>
        <Typography variant="h6">
          {intl.formatMessage({ id: 'general.Conversations' })}
        </Typography>
        <IconButton
          color="primary"
          onClick={onStartNewChat}
          disabled={isCreatingChat}
          sx={{ 
            borderRadius: '50%',
            width: 40,
            height: 40
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <Box sx={{ 
        flexGrow: 1, 
        minHeight: 0,
        overflow: 'auto'
      }}>
        <List>
          {conversations.map((conversation) => (
            <ConversationItem
              key={conversation.id}
              conversation={conversation}
              isSelected={selectedConversation?.id === conversation.id}
              onSelect={onSelectConversation}
              onEdit={onEditTitle}
              onDelete={onDeleteConversation}
            />
          ))}
          {conversations.length === 0 && (
            <Typography variant="body2" color="textSecondary" align="center" sx={{ p: 2 }}>
              {intl.formatMessage({ id: 'general.No-conversations-found' })}
            </Typography>
          )}
        </List>
      </Box>
    </Paper>
  );
};
