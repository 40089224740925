import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles/quotePdfStyles';
import { Quote } from '../../../../components/task/types';
import { useIntl } from 'react-intl';
import { MarkdownPDF } from '../../MarkdownPDF';

interface FunctionalitiesSectionProps {
  quote: Quote | null;
}

export const FunctionalitiesSection: React.FC<FunctionalitiesSectionProps> = ({ quote }) => {
  const intl = useIntl();

  return (
    <View style={styles.section}>
      <View style={styles.sectionTitleGroup}>
        <Text style={styles.sectionTitle}>
          {intl.formatMessage({ id: 'quote.pdf.functionalities' })}
        </Text>
        {quote?.quote_items?.map((qi, index) => (
          <View key={index} style={styles.functionItem}>
            {/* Keep title with at least the first part of the content */}
            <View wrap={false}>
              <Text style={styles.functionTitle}>{qi.title}</Text>
              <MarkdownPDF content={qi.description?.split('\n\n')[0] || ''} />
            </View>
            {/* Render the rest of the content if any */}
            {qi.description?.split('\n\n').slice(1).map((paragraph, i) => (
              <MarkdownPDF key={i} content={paragraph} />
            ))}
          </View>
        ))}
      </View>
    </View>
  );
};
