import { CloudUpload } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { FC } from "react";
import { parse } from "csv-parse/browser/esm";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { uploadCsvExpenses, getExpenses } from "../../redux/reducerSlices/expense";
import dayjs from "dayjs";
import axios from "axios";

type Props = {
  open: boolean;
  onClose: () => void;
};

export type CsvExpenseEntity = {
  Amount: string;
  Contract: string;
  Date: string;
  "Hourly rate": string;
  Hours: string;
  Memo: string;
  Talent: string;
};

export const ImportExpensesModal: FC<Props> = ({ onClose, open }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state);
  const { organizations } = useSelector((state: any) => state);

  const [file, setFile] = React.useState<File | null>(null);
  const [csvExpenses, setCsvExpenses] = React.useState<CsvExpenseEntity[]>([]);
  const [fromDate, setFromDate] = React.useState(dayjs().subtract(1, 'month'));
  const [toDate, setToDate] = React.useState(dayjs());
  const [loading, setLoading] = React.useState(false);

  // Find current organization
  const currentOrg = organizations.find((org: any) => org.id === user.selected_organization_id);
  const isUpworkEnabled = currentOrg?.upwork_enabled;

  const handleClear = () => {
    setFile(null);
    setCsvExpenses([]);
  };

  const handleImport = () => {
    dispatch(uploadCsvExpenses(csvExpenses));
    handleClear();
    onClose();
  };

  const handleUpworkImport = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/upwork/import-timesheets', {
        params: {
          from_date: fromDate.format('YYYY-MM-DD'),
          to_date: toDate.format('YYYY-MM-DD'),
          organization_id: user.selected_organization_id
        }
      });
      
      if (response.data.success) {
        dispatch(getExpenses());
        onClose();
      } else {
        console.error('Failed to import timesheets:', response.data);
      }
    } catch (error) {
      console.error('Error importing timesheets:', error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!file) return;

    const fr = new FileReader();

    const rows: string[][] = [];

    fr.onload = (e) => {
      const content = e.target.result.toString();
      const parser = parse(content);
      parser
        .on("data", (row) => rows.push(row))
        .on("end", () => {
          const headers = rows.shift();
          setCsvExpenses(
            rows.map((row) => {
              return row.reduce((acc, curr, idx) => {
                const header = headers[idx];
                acc[header] = curr;
                return acc;
              }, {} as CsvExpenseEntity);
            })
          );
        });
    };

    fr.readAsText(file);
  }, [file]);

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        <FormattedMessage
          id="expenses.ImportModalTitle"
          defaultMessage="Import Expenses"
        />
      </DialogTitle>
      <DialogContent>
        {/* Upwork Import Section */}
        {user.upwork_token && isUpworkEnabled && (
          <>
            <Typography variant="h6" sx={{ mb: 2 }}>
              <FormattedMessage
                id="expenses.ImportFromUpwork"
                defaultMessage="Import from Upwork"
              />
            </Typography>
            <Box display="flex" gap={2} alignItems="center" sx={{ mb: 3 }}>
              <DatePicker
                label="From Date"
                value={fromDate}
                onChange={(newValue) => setFromDate(newValue)}
                format="YYYY-MM-DD"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
              />
              <DatePicker
                label="To Date"
                value={toDate}
                onChange={(newValue) => setToDate(newValue)}
                format="YYYY-MM-DD"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
              />
              <Button
                variant="contained"
                onClick={handleUpworkImport}
                disabled={loading}
              >
                <FormattedMessage
                  id="expenses.ImportFromUpwork"
                  defaultMessage="Import from Upwork"
                />
              </Button>
            </Box>
            <Divider sx={{ my: 3 }} />
          </>
        )}

        {/* CSV Import Section */}
        <Typography variant="h6" sx={{ mb: 2 }}>
          <FormattedMessage
            id="expenses.ImportFromCSV"
            defaultMessage="Import from CSV"
          />
        </Typography>
        {!file && <FileInput onChange={(f) => setFile(f)} />}

        {!!csvExpenses.length && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(csvExpenses[0]).map((h) => (
                    <TableCell
                      sx={{
                        fontSize: 12,
                        whiteSpace: "nowrap",
                        fontWeight: "600",
                      }}
                      key={h}
                    >
                      {h}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {csvExpenses.map((e, ridx) => (
                  <TableRow key={ridx}>
                    {Object.values(e).map((v, vidx) => (
                      <TableCell
                        sx={{ fontSize: 12, whiteSpace: "nowrap" }}
                        key={v + ridx + vidx}
                      >
                        {v}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      {!!csvExpenses.length && (
        <DialogActions>
          <Button onClick={handleClear}>
            <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
          </Button>
          <Button onClick={handleImport}>
            <FormattedMessage id="general.Import" defaultMessage="Import" />
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const FileInput = ({ onChange }: { onChange: (f: File) => void }) => {
  return (
    <div
      style={{
        padding: 16,
        width: "100%",
        border: "1px dashed grey",
        position: "relative",
        height: 100,
        backgroundColor: "#fff",
        color: "grey",
        textAlign: "center",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CloudUpload />
      <span style={{ marginTop: 4, marginLeft: 5 }}>
        <FormattedMessage
          id="general.Click-or-drop-files"
          defaultMessage="Click or drop files in the box to upload them"
        />
      </span>
      <input
        type="file"
        accept=".csv"
        style={{
          width: "100%",
          height: 130,
          top: -30,
          left: 0,
          cursor: "pointer",
          position: "absolute",
        }}
        onChange={(e) => {
          onChange(e.target.files[0]);
        }}
      />
    </div>
  );
};
