import { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Conversation } from '../types/conversation';

export const useConversations = () => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);
  const [isCreatingChat, setIsCreatingChat] = useState(false);
  const intl = useIntl();

  const fetchConversations = useCallback(async () => {
    try {
      const response = await axios.get('/conversations');
      const conversationsData = Array.isArray(response.data) ? response.data : 
                              Array.isArray(response.data.data) ? response.data.data : [];
      
      const conversationsWithMessages = conversationsData.map((conv: any) => ({
        id: conv.id,
        title: conv.title || 'Untitled',
        threadId: conv.threadId || '',
        messages: Array.isArray(conv.messages) ? conv.messages : [],
        createdAt: conv.createdAt || new Date().toISOString(),
        updatedAt: conv.updatedAt || new Date().toISOString()
      }));
      setConversations(conversationsWithMessages);

      // If there's a selected conversation, update it with the latest data
      if (selectedConversation) {
        const updatedSelectedConv = conversationsWithMessages.find(conv => conv.id === selectedConversation.id);
        if (updatedSelectedConv) {
          setSelectedConversation(updatedSelectedConv);
        }
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
      toast.error(intl.formatMessage({ id: 'general.Error-loading-conversations' }));
    }
  }, [intl, selectedConversation]);

  const startNewChat = async () => {
    try {
      setIsCreatingChat(true);
      setSelectedConversation(null);
      
      const response = await axios.post('/conversations/start');
      const newConversation = response.data;
      
      if (!newConversation || typeof newConversation.id !== 'number') {
        throw new Error('Invalid conversation data received');
      }

      const normalizedConversation: Conversation = {
        id: newConversation.id,
        title: newConversation.title || 'New Chat',
        threadId: newConversation.threadId || '',
        messages: Array.isArray(newConversation.messages) ? newConversation.messages : [],
        createdAt: newConversation.createdAt || new Date().toISOString(),
        updatedAt: newConversation.updatedAt || new Date().toISOString()
      };
      
      setConversations(prevConversations => [normalizedConversation, ...prevConversations]);
      setSelectedConversation(normalizedConversation);
    } catch (error) {
      console.error('Error starting new chat:', error);
      toast.error(intl.formatMessage({ id: 'general.Error-creating-conversation' }));
    } finally {
      setIsCreatingChat(false);
    }
  };

  const handleEditConversationTitle = async (conversationId: number, newTitle: string) => {
    if (typeof conversationId !== 'number') return;

    try {
      const response = await axios.put(`/conversations/${conversationId}`, {
        title: newTitle
      });

      const updatedData = response.data.data || response.data;
      
      if (!updatedData || typeof updatedData.id !== 'number') {
        throw new Error('Invalid response data');
      }

      const normalizedConversation: Conversation = {
        id: updatedData.id,
        title: updatedData.title || 'Untitled',
        threadId: updatedData.threadId || '',
        messages: Array.isArray(updatedData.messages) ? updatedData.messages : [],
        createdAt: updatedData.createdAt || new Date().toISOString(),
        updatedAt: updatedData.updatedAt || new Date().toISOString()
      };

      setConversations(prevConversations => 
        prevConversations.map(conv => 
          conv.id === conversationId ? normalizedConversation : conv
        )
      );
      if (selectedConversation?.id === conversationId) {
        setSelectedConversation(normalizedConversation);
      }
      toast.success(intl.formatMessage({ id: 'general.Conversation-updated' }));
    } catch (error) {
      console.error('Error updating conversation:', error);
      toast.error(intl.formatMessage({ id: 'general.Error-updating-conversation' }));
    }
  };

  const handleDeleteConversation = async (conversationId: number) => {
    if (typeof conversationId !== 'number') return;

    try {
      await axios.delete(`/conversations/${conversationId}`);
      setConversations(prevConversations => 
        prevConversations.filter(conv => conv.id !== conversationId)
      );
      if (selectedConversation?.id === conversationId) {
        setSelectedConversation(null);
      }
      toast.success(intl.formatMessage({ id: 'general.Conversation-deleted' }));
    } catch (error) {
      console.error('Error deleting conversation:', error);
      toast.error(intl.formatMessage({ id: 'general.Error-deleting-conversation' }));
    }
  };

  return {
    conversations,
    selectedConversation,
    isCreatingChat,
    setSelectedConversation,
    fetchConversations,
    startNewChat,
    handleEditConversationTitle,
    handleDeleteConversation
  };
};
