import React from "react";
import { FormattedMessage } from "react-intl";
import { TaskRow } from "./types";
import { getMessages } from "../../App";
import { Client } from "../company/types";

export const localizeTaskStatus = (status: string) => {
  switch (status) {
    case "Gathering Data":
      return (
        <FormattedMessage
          id="task.status.Gathering-Data"
          defaultMessage="Gathering Data"
        />
      );
    case "To Estimate":
      return (
        <FormattedMessage
          id="task.status.To-Estimate"
          defaultMessage="To Estimate"
        />
      );
    case "Pending Approval":
      return (
        <FormattedMessage
          id="task.status.Pending-Approval"
          defaultMessage="Pending Approval"
        />
      );
    case "To Do":
      return <FormattedMessage id="task.status.To-Do" defaultMessage="To Do" />;
    case "In Progress":
      return (
        <FormattedMessage
          id="task.status.In-Progress"
          defaultMessage="In Progress"
        />
      );
    case "In Review":
      return (
        <FormattedMessage
          id="task.status.In-Review"
          defaultMessage="In Review"
        />
      );
    case "In Staging":
      return (
        <FormattedMessage
          id="task.status.In-Staging"
          defaultMessage="In Staging"
        />
      );
    case "Closed":
      return (
        <FormattedMessage id="task.status.Closed" defaultMessage="Closed" />
      );
    default:
      console.error("unexpected status: ", status);
      return (
        <FormattedMessage
          id="general-status.unexpected-status"
          defaultMessage="unexpected status"
        />
      );
  }
};

export const localizeTaskLabel = (label: string) => {
  switch (label) {
    case "Bug":
      return (
        <FormattedMessage
          id="task.type.bug"
          defaultMessage="Bug"
        />
      );
    case "Chore":
      return (
        <FormattedMessage
          id="task.type.chore"
          defaultMessage="Chore"
        />
      );
    case "Feature":
      return (
        <FormattedMessage
          id="task.type.feature"
          defaultMessage="Feature"
        />
      );
    default:
      return (
        <FormattedMessage
          id="task.type.unexpected-type"
          defaultMessage="Unexpected type"
        />
      );
  }
};

export const mapTaskStatus = (trelloSection) => {
  switch (String(trelloSection).toLocaleLowerCase().trim()) {
    case "to estimate":
      return {
        status: "To Estimate",
        pos: 10_000_000_000,
      };
    case "pending approval":
      return {
        status: "Pending Approval",
        pos: 20_000_000_000,
      };
    case "to do":
      return {
        status: "To Do",
        pos: 30_000_000_000,
      };
    case "in progress":
      return {
        status: "In Progress",
        pos: 40_000_000_000,
      };
    case "in review":
      return {
        status: "In Review",
        pos: 50_000_000_000,
      };
    case "staging":
    case "in staging":
      return {
        status: "In Staging",
        pos: 60_000_000_000,
      };
    case "done":
    case "closed":
      return {
        status: "Closed",
        pos: 70_000_000_000,
      };
    default:
      return {
        status: "Gathering Data",
        pos: 0,
      };
  }
};

export const truncate = (str: string, num: number) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

export const getInvoiceTitle = (task: TaskRow, company: Client) => {
  if (!task) return null;

  let title = task.title;

  const clientLocale = company.language;
  const messages = getMessages(clientLocale);

  const hasInvoiceItems = task.invoice_items.length;

  if (task.quote_items.length) {
    const quoteItem = task.quote_items.reduce((prev, current) =>
      prev.quote.id > current.quote.id ? prev : current
    );
    const { partial_invoicing } = quoteItem.quote;
    const quote = messages["invoice-modal.Quote"];
    const percentage = hasInvoiceItems
      ? 100 - partial_invoicing
      : partial_invoicing;
    const status = hasInvoiceItems
      ? messages["invoice-modal.On-delivery"]
      : messages["invoice-modal.On-approval"];
    title = `${quote} #${quoteItem.quote.number}: ${
      quoteItem.title || title
    } ${percentage > 0 ? `(${percentage}% ${status})` : ''}`;
  }

  return title;
};

export const formatTaskTitle = (task: any) => {
  let projectShortName = task.project?.code;

  if (!projectShortName) {
    projectShortName = task.project?.name
      ?.split(" ")
      .map((word: string) => word[0])
      .join("");
  }

  return `${projectShortName}-${task.trello_id_short || task.id} ${task.title}`;
};
