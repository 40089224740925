import React from 'react';
import { Box, Paper } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { Message } from '../../types/conversation';

// Custom components for ReactMarkdown
const markdownComponents = {
  // Open all links in new tab with proper accessibility
  a: ({ node, children, ...props }) => (
    <a target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  )
};

interface ChatMessageProps {
  message: Message;
}

export const ChatMessage: React.FC<ChatMessageProps> = ({ message }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: message.isUser ? 'flex-end' : 'flex-start',
      mb: 2,
    }}
  >
    <Paper
      sx={{
        p: 2,
        maxWidth: '70%',
        bgcolor: message.isUser ? 'primary.light' : 'grey.100',
        color: message.isUser ? 'white' : 'text.primary',
        borderRadius: '20px',
        ...(message.isUser ? {
          borderBottomRightRadius: '4px',
        } : {
          borderBottomLeftRadius: '4px',
        }),
        '& .markdown-content': {
          '& pre': {
            backgroundColor: message.isUser ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
            padding: '8px',
            borderRadius: '4px',
            overflowX: 'auto',
          },
          '& code': {
            backgroundColor: message.isUser ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
            padding: '2px 4px',
            borderRadius: '4px',
            fontFamily: 'monospace',
          },
          '& p': {
            margin: '0.5em 0',
            '&:first-of-type': {
              marginTop: 0,
            },
            '&:last-of-type': {
              marginBottom: 0,
            },
          },
          '& ul, & ol': {
            marginTop: '0.5em',
            marginBottom: '0.5em',
            paddingLeft: '1.5em',
          },
          '& a': {
            color: message.isUser ? 'inherit' : 'primary.main',
            textDecoration: 'underline',
          },
          '& img': {
            maxWidth: '100%',
            height: 'auto',
          },
          '& blockquote': {
            borderLeft: '3px solid',
            borderColor: message.isUser ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.2)',
            margin: '0.5em 0',
            paddingLeft: '1em',
          },
          '& table': {
            borderCollapse: 'collapse',
            width: '100%',
            margin: '0.5em 0',
            '& th, & td': {
              border: '1px solid',
              borderColor: message.isUser ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)',
              padding: '4px 8px',
            },
          },
        },
      }}
    >
      <Box className="markdown-content">
        <ReactMarkdown components={markdownComponents}>
          {message.text}
        </ReactMarkdown>
      </Box>
    </Paper>
  </Box>
);
