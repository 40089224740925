import { QuoteStatus, Currency, Quote, Client } from "./types";

export const grid = 4;

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: `0px 0px 3px`,
  margin: `0 0 2px 0`,
  borderRadius: 5,
  background: "white",
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightgrey" : "white",
  padding: grid,
});

const emptyClient: Partial<Client> = {
  id: 0,
  name: "",
  currency: Currency.SEK,
  base_rate: 0,
  hourly_rate: 0
};

export const emptyQuote: Quote = {
  number: 0,
  date: new Date(),
  delivery_time: 14,
  expiration_date: new Date(new Date().setDate(new Date().getDate() + 14)),
  expiration_days: 14,
  currency: Currency.SEK,
  status: QuoteStatus.draft,
  company: emptyClient as Client,
  quote_items: [],
  base_rate: 0,
  partial_invoicing: 50,
};
