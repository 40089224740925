import axios from "axios";
import toast from "react-hot-toast";
import qs from "qs";

import { refreshTasks } from "./task";
import { Invoice } from "../../components/task/types";
import { getPOsNotFullyInvoiced } from "./purchase-order";
import { getSubscriptionsToInvoice } from "./subscription";
import { getIntl } from "../../GlobalIntlProvider";

const initialState = {
  invoices: [],
  draftInvoices: [],
  pagination: {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    total: 0,
  },
};

const generateInvoiceFillQuery = ({ user, pagination = {} }) => {
  return qs.stringify(
    {
      populate: {
        company: {
          fields: ["name", "requires_po"],
        },
        invoice_items: {
          fields: ["price", "title", "is_markup_discount"],
          populate: {
            task: {
              fields: ["title", "status", "description"],
              populate: {
                project: {
                  fields: ["name"],
                },
                seen_by: {
                  fields: ["id"],
                  filters: {
                    id: user?.id,
                  },
                },
              },
            },
          },
          sort: ["order:asc"],
        },
        purchase_order: true,
        invoice_to: {
          fields: ["id", "name"],
        },
      },
      fields: ["number", "status", "date", "currency", "fortnox_id"],
      sort: ["number:desc"],
      pagination,
    },
    {
      encodeValuesOnly: true,
    }
  );
};

const serializeInvoice = (q: any) => {
  const invoice: Invoice = {
    id: q.id,
    ...q.attributes,
    company: {
      id: parseInt(q.attributes.company.data?.id || 0),
      ...q.attributes.company.data?.attributes,
    },
    invoice_items: q.attributes.invoice_items.data?.map((qi: any) => ({
      id: parseInt(qi.id),
      ...qi.attributes,
      task: {
        id: parseInt(qi.attributes.task.data?.id || 0),
        ...qi.attributes.task.data?.attributes,
        project: {
          id: qi.attributes.task.data?.attributes?.project?.data?.id,
          ...qi.attributes.task.data?.attributes?.project?.data?.attributes,
        },
        seen_by: qi.attributes.task.data?.attributes?.seen_by?.data || [],
      },
    })),
    purchase_order: Boolean(!q.attributes.purchase_order.data?.id)
      ? null
      : {
          id: parseInt(q.attributes.purchase_order.data?.id),
          ...q.attributes.purchase_order.data?.attributes,
        },
    invoice_to: Boolean(!q.attributes.invoice_to.data?.id)
      ? null
      : {
          id: parseInt(q.attributes.invoice_to.data?.id),
          name: q.attributes.invoice_to.data?.attributes.name,
        },
  };

  return invoice;
};

const invoiceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "INVOICES/GETALL":
      return {
        ...state,
        ...action.payload,
      };
    case "INVOICES/CREATE":
      if (action.payload == null) return state;
      return {
        ...state,
        invoices: [...state.invoices, action.payload],
      };
    case "INVOICES/REMOVE":
      return {
        ...state,
        invoices: state.invoices.filter(
          (invoice) => invoice.id !== action.payload.invoiceID
        ),
      };
    case "INVOICES/EDIT":
      return {
        ...state,
        invoices: state.invoices.map((invoice) =>
          invoice.id === action.payload.invoice.id
            ? action.payload.invoice
            : invoice
        ),
      };
    case "INVOICE/ITEMS/REMOVE":
      return {
        ...state,
        invoices: state.invoices.map((invoice) => {
          invoice.invoice_items = invoice.invoice_items.filter(
            (qi: any) => qi.id !== action.payload.qiId
          );
          return invoice;
        }),
      };
    default:
      return state;
  }
};

export const internalInvoiceTaskSeenBy = (saw_task_id: any) => {
  return async (dispatch: any, getState: any) => {
    const {
      invoices: { invoices },
      user,
    } = getState();

    dispatch({
      type: "INVOICES/GETALL",
      payload: {
        invoices: invoices.map((invoice) => {
          return {
            ...invoice,
            invoice_items:
              invoice.invoice_items?.map((ii: any) => {
                if (ii.task?.id === saw_task_id)
                  ii.task.seen_by = [{ id: user.id }];

                return ii;
              }) || [],
          };
        }),
      },
    });
  };
};

export const getInvoices = (page?: number, pageSize?: number) => {
  return async (dispatch: any, getState: any) => {
    const {
      user,
      invoices: { pagination: statePagination },
    } = getState();

    const pagination = {
      page: page || statePagination.page,
      pageSize: pageSize || statePagination.pageSize,
    };

    const query = generateInvoiceFillQuery({ user, pagination });

    try {
      const response = await axios(`/invoices?${query}`);
      const {
        data,
        meta: { pagination },
      } = response.data;

      const invoices = data.map((q: any) => serializeInvoice(q));

      dispatch({
        type: "INVOICES/GETALL",
        payload: {
          invoices,
          pagination,
        },
      });
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.invoices.fetch-error",
            defaultMessage: "Could not fetch invoices. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const fetchSingleInvoiceWithouState = async (
  invoiceId: string,
  user
) => {
  const query = generateInvoiceFillQuery({ user });

  const response = await axios(`/invoices/${invoiceId}?${query}`);

  return serializeInvoice(response.data.data);
};

export const getDraftInvoices = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios(
        `/invoices?filters[status]=draft&pagination[pageSize]=100&populate=company`
      );
      const invoices = response.data.data;

      dispatch({
        type: "INVOICES/GETALL",
        payload: {
          draftInvoices: invoices.map((q: any) => {
            return {
              id: q.id,
              ...q.attributes,
              company: {
                id: q.attributes.company.data.id,
                ...q.attributes.company.data.attributes,
              },
            };
          }),
        },
      });
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.draft-invoices.fetch-error",
            defaultMessage: "Could not fetch draft invoices. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const getInvoiceItemsInIds = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`/invoices-items`);
      const invoices = response.data;

      dispatch({
        type: "INVOICES/GETALL",
        payload: {
          invoices,
        },
      });
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.invoices.fetch-error",
            defaultMessage: "Could not fetch  invoices. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

const handleInvoiceResponse = (response: any) => {
  if (response.data.data.attributes.fortnox_error) {
    toast.error(
      getIntl().formatMessage(
        {
          id: "toast.invoices.fortnox-sync-error",
          defaultMessage: "Invoice saved but failed to sync with Fortnox: {error}",
        },
        {
          error: response.data.data.attributes.fortnox_error,
        }
      ),
      {
        duration: 5000,
      }
    );
  }
  return response;
};

export const createInvoice = (payload: any) => {
  return async (dispatch: any, getState: any) => {
    const { addToInvoiceTab } = getState().task;

    try {
      const { invoice, task = null, price, subscription = null } = payload;

      if (!invoice.purchase_order?.id) {
        delete invoice.purchase_order;
      }

      const response = await axios.post(`/invoices`, {
        data: invoice,
      });

      handleInvoiceResponse(response);

      const newInvoice = {
        id: response.data.data.id,
        ...response.data.data.attributes,
      };

      if (task) {
        dispatch(
          createInvoiceItem({
            data: {
              invoice: newInvoice,
              task,
              price,
              subscription,
            },
          })
        );

        dispatch(getDraftInvoices());
      } else {
        dispatch(getInvoices());
      }

      if (addToInvoiceTab) {
        dispatch(refreshTasks());
        dispatch(getSubscriptionsToInvoice());
      }

      toast.success(
        getIntl().formatMessage({
          id: "toast.invoice.created",
          defaultMessage: "Invoice created",
        })
      );
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.invoices.create-error",
            defaultMessage: "Could not create invoice. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const createInvoiceItem = (payload: any) => {
  return async (dispatch: any, getState: any) => {
    const { task, subscription_item } = payload.data;
    const title = payload.title || task?.title || subscription_item?.title;
    const sortedAcceptedQIs =
      task?.quote_items
        ?.filter((qi) => {
          return qi.status === "accepted";
        })
        .sort((a: any, b: any) => b.id - a.id) || [];
    let price = payload.data.price;
    let conversion_rate = 0;
    let local_price = 0;

    if (sortedAcceptedQIs[0]) {
      conversion_rate = sortedAcceptedQIs[0].conversion_rate;

      if (price) {
        local_price = price * conversion_rate;
      } else {
        const partialInvoicePercentage =
          sortedAcceptedQIs[0].quote.partial_invoicing || 50;
        price = sortedAcceptedQIs[0].price * (partialInvoicePercentage / 100);
        local_price = sortedAcceptedQIs[0].local_price;
      }
    }

    payload.data.price = price;
    payload.data.conversion_rate = conversion_rate;
    payload.data.local_price = local_price;
    payload.data.title = title;

    if (subscription_item) {
      payload.data.subscription_item = subscription_item.id;
    }

    try {
      await axios.post(`/invoice-items`, payload);

      toast.success(
        getIntl().formatMessage(
          {
            id: "toast.task.added-to-invoice",
            defaultMessage: "{task_title} added to invoice #{invoice_number}",
          },
          {
            task_title: title,
            invoice_number: payload.data.invoice.number,
          }
        )
      );

      dispatch(refreshTasks());
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.invoice-item.create-error",
            defaultMessage: "Could not create invoice item. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const removeInvoiceItems = (payload: any) => {
  return async (dispatch: any) => {
    try {
      const { invoice, task, subscription } = payload;
      const entity = task || subscription || {};

      if (entity?.invoiceItemsMap[invoice.id]) {
        for (const qi of entity.invoiceItemsMap[invoice.id]) {
          await axios.delete(`/invoice-items/${qi.id}`);
        }
      }

      toast.success(
        getIntl().formatMessage(
          {
            id: "toast.task.removed-from-invoice",
            defaultMessage:
              "{task_title} removed from invoice #{invoice_number}",
          },
          {
            task_title: entity.title,
            invoice_number: invoice.number,
          }
        )
      );

      if (subscription) {
        return dispatch(getSubscriptionsToInvoice());
      } else {
        dispatch(refreshTasks());
      }
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.invoice-item.remove",
            defaultMessage: "Could not remove invoice items. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const deleteInvoice = (invoiceID: any) => {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/invoices/${invoiceID}`);

      dispatch({
        type: "INVOICES/REMOVE",
        payload: {
          invoiceID,
        },
      });

      toast.success(
        getIntl().formatMessage({
          id: "toast.invoice.deleted",
          defaultMessage: "Invoice deleted",
        })
      );
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.invoice.delete-error",
            defaultMessage: "Could not delete invoice. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const deleteInvoiceItem = (qiId: any, showToast = true) => {
  return async (dispatch: any) => {
    try {
      await axios.delete(`/invoice-items/${qiId}`);

      dispatch({
        type: "INVOICE/ITEMS/REMOVE",
        payload: {
          qiId,
        },
      });

      if (showToast) {
        toast.success(
          getIntl().formatMessage({
            id: "toast.invoice-item.deleted",
            defaultMessage: "Invoice item deleted",
          })
        );
      }
    } catch (err) {
      console.error(err);
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.invoice-item.delete-error",
            defaultMessage: "Could not delete invoice item. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export const updateInvoice = (invoiceDetails: any) => {
  return async (dispatch: any) => {
    try {
      if (!invoiceDetails.data.company.requires_po) {
        invoiceDetails.data.purchase_order = null;
      }

      const response = await axios.put(`/invoices/${invoiceDetails.data.id}`, {
        data: invoiceDetails.data,
      });

      handleInvoiceResponse(response);

      dispatch(getInvoices());
      dispatch(getPOsNotFullyInvoiced());

      toast.success(
        getIntl().formatMessage({
          id: "toast.invoice.updated",
          defaultMessage: "Invoice updated",
        })
      );
    } catch (err: any) {
      toast.error(
        getIntl().formatMessage(
          {
            id: "toast.invoice.update-error",
            defaultMessage: "Could not update invoice. Reason: {error}",
          },
          {
            error: String(err),
          }
        ),
        {
          duration: 5000,
        }
      );
    }
  };
};

export default invoiceReducer;
