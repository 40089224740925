import React, { useRef, useEffect, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Quote, QuoteStatus, Currency, Client } from "../types";
import languages, { DEFAULT_LANGUAGE } from "../../../../languages";

interface QuoteFormProps {
  quote: Quote;
  lastQuoteNum: number;
  showFormErrors: boolean;
  companyErr: boolean;
  companies: Client[];
  companiesMap: { [key: string]: Client };
  setCompanyErr: (err: boolean) => void;
  setQuote: (quote: Quote) => void;
  setQis: (updateFn: (qis: any[]) => any[]) => void;
  handleOnChangeFormField: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | (Event & { target: { value: string; name: string } }),
    name?: string | null
  ) => void;
}

const QuoteForm: React.FC<QuoteFormProps> = ({
  quote,
  lastQuoteNum,
  showFormErrors,
  companyErr,
  companies,
  companiesMap,
  setCompanyErr,
  setQuote,
  setQis,
  handleOnChangeFormField,
}) => {
  const intl = useIntl();
  const previousLanguageRef = useRef<string>();

  // eslint-disable-next-line no-template-curly-in-string
  const projectNameVar = "${projectName}";

  const getDefaultBody = useCallback((language: string = DEFAULT_LANGUAGE) => {
    const messages = languages[language] || languages[DEFAULT_LANGUAGE];
    const defaultMessages = languages[DEFAULT_LANGUAGE];
    const contextTitle = messages?.['quote.pdf.context'] || defaultMessages['quote.pdf.context'];
    const contextText = messages?.['quote.pdf.contextText'] || defaultMessages['quote.pdf.contextText'];
    return `# ${contextTitle}\n\n${contextText.replace('{projectName}', projectNameVar)}`;
  }, [projectNameVar]);

  const isDefaultBody = useCallback((body?: string) => {
    if (!body) return true;
    
    // Get all supported languages and check if body matches any of their default texts
    return Object.keys(languages).some(lang => {
      try {
        const defaultText = getDefaultBody(lang);
        return body === defaultText;
      } catch (e) {
        return false;
      }
    });
  }, [getDefaultBody]);

  useEffect(() => {
    const currentLanguage = quote.company?.language || DEFAULT_LANGUAGE;
    if (currentLanguage !== previousLanguageRef.current) {
      if (isDefaultBody(quote.body)) {
        setQuote({
          ...quote,
          body: getDefaultBody(currentLanguage)
        });
      }
      previousLanguageRef.current = currentLanguage;
    }
  }, [quote, quote.company?.language, setQuote, isDefaultBody, getDefaultBody]);

  return (
    <Grid container alignItems="flex-start" justifyContent="flex-end" spacing={1}>
      <Grid item sm={3}>
        <TextField
          value={quote.number || lastQuoteNum}
          id="number"
          label={<FormattedMessage id="general.Number" defaultMessage="Number" />}
          type="number"
          name="number"
          fullWidth
          required
          variant="outlined"
          error={showFormErrors && quote.number === 0 && lastQuoteNum === 0}
          helperText={
            showFormErrors && quote.number === 0
              ? intl.formatMessage({
                  id: "general.Quote-number-is-required",
                  defaultMessage: "Quote number is required",
                })
              : ""
          }
          onChange={(event) => handleOnChangeFormField(event)}
        />
      </Grid>
      <Grid item sm={3}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="company-label">
            <FormattedMessage id="general.Client" defaultMessage="Client" />
          </InputLabel>
          <Select
            labelId="company-label"
            label={<FormattedMessage id="general.Client" defaultMessage="Client" />}
            name="company"
            error={companyErr}
            onChange={(event) => {
              const company = companiesMap[event.target.value];
              if (company) {
                setCompanyErr(false);
                setQuote({
                  ...quote,
                  company,
                  currency: company.currency,
                  base_rate: company.base_rate,
                  partial_invoicing: company.partial_invoicing || 50,
                });
              }
            }}
            value={quote.company.id}
          >
            <MenuItem value={0}>
              <FormattedMessage id="general.Select-client" defaultMessage="Select client" />
            </MenuItem>
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
          {companyErr && (
            <FormHelperText sx={{ color: "#bf3333", marginLeft: "16px !important" }}>
              <FormattedMessage
                id="general.Client-is-required"
                defaultMessage="Client is required"
              />
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item sm={3}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="currency-label">
            <FormattedMessage id="general.Currency" defaultMessage="Currency" />
          </InputLabel>
          <Select
            labelId="currency-label"
            label={<FormattedMessage id="general.Currency" defaultMessage="Currency" />}
            name="currency"
            onChange={(event) => handleOnChangeFormField(event)}
            value={quote.currency.toString()}
          >
            {Object.keys(Currency)
              .filter((q) => isNaN(Number(q)))
              .map((currency, i) => (
                <MenuItem key={currency + i} value={currency}>
                  {currency}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={3}>
        <TextField
          value={quote.delivery_time || 0}
          id="delivery_time"
          label={
            <FormattedMessage id="general.Delivery-time" defaultMessage="Delivery time" />
          }
          type="number"
          name="delivery_time"
          fullWidth
          variant="outlined"
          onChange={(event) => {
            const newVal = Number(event.target.value);
            if (newVal >= 0) {
              setQuote({
                ...quote,
                delivery_time: newVal,
              });
            } else {
              setQuote({
                ...quote,
                delivery_time: 0,
              });
            }
          }}
        />
      </Grid>
      <Grid item sm={6}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            id="date"
            label={<FormattedMessage id="general.Date" defaultMessage="Date" />}
            type="date"
            fullWidth
            value={quote.date.toISOString().slice(0, 10)}
            onChange={(event) => {
              if (event.target.value) {
                setQuote({
                  ...quote,
                  date: new Date(event.target.value),
                  expiration_date: new Date(
                    new Date(event.target.value).setDate(
                      new Date(event.target.value).getDate() + quote.delivery_time
                    )
                  ),
                });
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item sm={6}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            id="date"
            label={
              <FormattedMessage
                id="general.Expiration-date"
                defaultMessage="Expiration date"
              />
            }
            type="date"
            fullWidth
            value={quote.expiration_date.toISOString().slice(0, 10)}
            InputProps={{
              inputProps: {
                min: quote.date.toISOString().slice(0, 10),
              },
            }}
            onChange={(event: any) => {
              const date = new Date(event.target.value);
              if (event.target.value) {
                setQuote({
                  ...quote,
                  expiration_date: date,
                  delivery_time: Math.ceil(
                    (date.getTime() - quote.date.getTime()) / (1000 * 3600 * 24)
                  ),
                });
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item sm={4}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="status-label">
            <FormattedMessage id="general.Status" defaultMessage="Status" />
          </InputLabel>
          <Select
            labelId="status-label"
            label={<FormattedMessage id="general.Status" defaultMessage="Status" />}
            name="status"
            onChange={(event) => handleOnChangeFormField(event)}
            value={quote.status.toString()}
          >
            {Object.keys(QuoteStatus)
              .filter((q) => isNaN(Number(q)))
              .map((status, i) => (
                <MenuItem key={status + i} value={status}>
                  {status}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={4}>
        <TextField
          value={quote.base_rate || 0}
          id="base_rate"
          label={<FormattedMessage id="general.Base-rate" defaultMessage="Base rate" />}
          type="number"
          name="base_rate"
          fullWidth
          variant="outlined"
          onChange={(event) => {
            if (Number(event.target.value) >= 0) {
              handleOnChangeFormField(event);
              setQis((qis) =>
                qis.map((qi) => {
                  if (qi.task?.estimates?.length) {
                    const estimate = qi.task.estimates?.reduce((a, b) => {
                      return a.hours > b.hours ? a : b;
                    });
                    qi.price = estimate.hours * Number(event.target.value);
                  }
                  return qi;
                })
              );
            }
          }}
        />
      </Grid>
      <Grid item sm={4}>
        <TextField
          value={quote.partial_invoicing || 50}
          id="partial_invoicing"
          label={
            <FormattedMessage
              id="general.%-invoiced-on-approval"
              defaultMessage="% invoiced on approval"
            />
          }
          type="number"
          name="partial_invoicing"
          fullWidth
          variant="outlined"
          onChange={(event) => {
            const val = Number(event.target.value);
            if (val >= 0 && val <= 100) {
              handleOnChangeFormField(event);
            }
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          value={quote.body || getDefaultBody(quote.company?.language)}
          id="body"
          label={<FormattedMessage id="general.Context" defaultMessage="Context" />}
          name="body"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          onChange={(event) => handleOnChangeFormField(event)}
        />
      </Grid>
    </Grid>
  );
};

export default QuoteForm;
