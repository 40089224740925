import axios from "axios";
import { toast } from "react-hot-toast";
import { refreshMe } from "./me";
import { getOrganizations } from "./organization";
import { changeOrganization } from "./user";

const initialState = {
  organizations: [],
  pagination: {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    total: 0,
  },
  shouldInit: true,
};

const OrganizationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "OWNED_ORGS/GETALL":
      return {
        ...state,
        ...action.payload,
        shouldInit: false,
      };
    default:
      return state;
  }
};

export const getOwnedOrganizations = (page?: number, pageSize?: number) => {
  return async (dispatch: any) => {
    try {
      const res = await axios.get("/owned-organizations");
      const { results: organizations, pagination } = res.data;

      dispatch({
        type: "OWNED_ORGS/GETALL",
        payload: {
          organizations,
          pagination,
        },
      });
    } catch (err) {
      console.error(err);
      toast.error(`Could not get organizations. Reason: ${String(err)}`);
    }
  };
};

export const createOrganization = (organizationData: any) => {
  return async (dispatch: any) => {
    try {
      const createdOrg = await axios.post(
        "/owned-organizations",
        organizationData
      );

      dispatch(changeOrganization(createdOrg.data.id));
      dispatch(getOwnedOrganizations());
      dispatch(getOrganizations());
    } catch (err) {
      console.error(err);
      toast.error(`Could not create organization. Reason: ${String(err)}`);
    }
  };
};

export const updateOrganization = (organizationData: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      await axios.put(`/owned-organizations/${organizationData.id}`, {
        ...organizationData,
      });

      dispatch(getOwnedOrganizations());
      dispatch(getOrganizations());

      // If updating the currently selected organization, refresh user data
      const { user } = getState();
      if (user.selected_organization_id === organizationData.id) {
        dispatch(refreshMe());
      }
    } catch (err) {
      console.error(err);
      toast.error(`Could not update organization. Reason: ${String(err)}`);
    }
  };
};

export const deleteOrganization = (organizationId: number) => {
  return async (dispatch: any, getState: any) => {
    const { user } = getState();

    try {
      await axios.delete(`/owned-organizations/${organizationId}`);

      dispatch(getOwnedOrganizations());
      dispatch(getOrganizations());

      if (user.selected_organization_id === organizationId) {
        dispatch(refreshMe());
      }
    } catch (err) {
      console.error(err);
      toast.error(`Could not delete organization. Reason: ${String(err)}`);
    }
  };
};

export default OrganizationReducer;
