import { toast } from "react-hot-toast";
import axios from "axios";
import { refreshMe } from "./me";

const initialState = {
  loading: false,
  timesheets: [],
};

const upworkReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "UPWORK/LOADING/TRUE":
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case "UPWORK/LOADING/FALSE":
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case "UPWORK/SET/TIMESHEETS":
      return {
        ...initialState,
        ...state,
        timesheets: action.payload.timesheets,
      };
    case "UPWORK/SET/STATUS/CONNECTED":
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case "UPWORK/SET/STATUS/DISCONNECTED":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const connectUpwork = () => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: "UPWORK/LOADING/TRUE" });

      // Get auth URL from backend
      const response = await axios.get('/upwork/auth-url');
      const { authUrl } = response.data;

      // Open Upwork OAuth page in a new window
      const width = 600;
      const height = 700;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2;
      const features = `width=${width},height=${height},left=${left},top=${top}`;

      window.open(authUrl, "Upwork Authorization", features);

      // Poll for connection status
      const checkConnection = setInterval(async () => {
        try {
          const user = await axios.get('/users/me');
          if (user.data.upwork_token) {
            clearInterval(checkConnection);
            dispatch({ type: "UPWORK/SET/STATUS/CONNECTED" });
            dispatch(refreshMe());
            toast.success("Successfully connected to Upwork");
          }
        } catch (error) {
          console.error("Error checking Upwork connection:", error);
        }
      }, 2000);

      // Stop polling after 2 minutes
      setTimeout(() => {
        clearInterval(checkConnection);
        dispatch({ type: "UPWORK/LOADING/FALSE" });
      }, 120000);

    } catch (err) {
      console.error(err);
      dispatch({ type: "UPWORK/LOADING/FALSE" });
      toast.error(`Could not connect to Upwork. Reason: ${String(err)}`);
    }
  };
};

export const handleUpworkCallback = (code: string) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: "UPWORK/LOADING/TRUE" });

      // Exchange code for token with authenticated request
      await axios.post('/upwork/exchange-token', { code });

      dispatch({ type: "UPWORK/SET/STATUS/CONNECTED" });
      dispatch(refreshMe());
      toast.success("Successfully connected to Upwork");

    } catch (err: any) {
      console.error(err);
      dispatch({ type: "UPWORK/LOADING/FALSE" });
      
      // Handle expired code case
      if (err.response?.status === 400) {
        toast.error("Authorization code expired. Please try connecting again.");
        // Trigger a new connection attempt
        dispatch(connectUpwork());
      } else {
        toast.error(`Could not connect to Upwork. Reason: ${String(err)}`);
      }
    }
  };
};

export const disconnectUpwork = () => {
  return async (dispatch: any) => {
    try {
      await axios.post('/upwork/disconnect');
      dispatch({ type: "UPWORK/SET/STATUS/DISCONNECTED" });
      dispatch(refreshMe());
      toast.success("Successfully disconnected from Upwork");
    } catch (err) {
      console.error(err);
      toast.error(`Could not disconnect from Upwork. Reason: ${String(err)}`);
    }
  };
};

export const importTimesheets = (organizationId: number, fromDate: string, toDate: string) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: "UPWORK/LOADING/TRUE" });

      const response = await axios.get(`/upwork/import-timesheets`, {
        params: {
          organization_id: organizationId,
          from_date: fromDate,
          to_date: toDate
        }
      });

      dispatch({ type: "UPWORK/LOADING/FALSE" });

      const { results } = response.data;
      if (results.errors.length > 0) {
        toast.error(`Imported ${results.imported} timesheets with ${results.errors.length} errors`);
      } else {
        toast.success(`Successfully imported ${results.imported} timesheets`);
      }

      return results;
    } catch (err) {
      console.error(err);
      dispatch({ type: "UPWORK/LOADING/FALSE" });
      toast.error(`Could not import timesheets. Reason: ${String(err)}`);
      throw err;
    }
  };
};

export default upworkReducer;
