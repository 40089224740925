import { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Conversation } from '../types/conversation';

interface UseChatProps {
  selectedConversation: Conversation | null;
  setSelectedConversation: (conversation: Conversation | null) => void;
  fetchConversations: () => Promise<void>;
}

export const useChat = ({ selectedConversation, setSelectedConversation, fetchConversations }: UseChatProps) => {
  const [message, setMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedConversation?.messages, isTyping]);

  const handleSendMessage = async () => {
    if (!message.trim() || !selectedConversation || typeof selectedConversation.id !== 'number') return;

    const userMessage = message.trim();
    setMessage('');

    // Immediately update UI with user's message
    const updatedMessages = [
      ...(Array.isArray(selectedConversation.messages) ? selectedConversation.messages : []),
      { text: userMessage, isUser: true }
    ];

    const conversationWithUserMessage = {
      ...selectedConversation,
      messages: updatedMessages
    };

    setSelectedConversation(conversationWithUserMessage);

    try {
      setIsTyping(true);
      const response = await axios.post(`/conversations/${selectedConversation.id}/chat`, {
        message: userMessage,
        threadId: selectedConversation.threadId
      });

      if (!response.data || typeof response.data.reply !== 'string') {
        throw new Error('Invalid response data');
      }

      // Get the latest conversation data to include any title updates
      const conversationResponse = await axios.get(`/conversations/${selectedConversation.id}`);
      const latestConversation = conversationResponse.data;

      const updatedConversation = {
        ...latestConversation,
        messages: [
          ...updatedMessages,
          { text: response.data.reply, isUser: false }
        ]
      };

      setSelectedConversation(updatedConversation);
      await fetchConversations(); // Refresh the conversation list to get any title updates
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error(intl.formatMessage({ id: 'general.Error-sending-message' }));
    } finally {
      setIsTyping(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return {
    message,
    isTyping,
    messagesEndRef,
    setMessage,
    handleSendMessage,
    handleKeyPress
  };
};
