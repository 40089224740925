import React, { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import AppBar from "./components/AppBar";
import TasksDashboard from "./pages/TasksDashboard";
import TicketsPage from "./pages/Tickets";
import QuotesPage from "./pages/Quotes";
import InvoicesPage from "./pages/Invoices";
import UsersPage from "./pages/Users";
import SubscriptionsPage from "./pages/Subscriptions";
import Login from "./components/login/Login";
import LoginRedirect from "./components/login/LoginRedirect";
import OrganizationsPage from "./pages/Organizations";
import ProfilePage from "./pages/Profile";
import DashboardPage from "./pages/Dashboard";
import PurchaseOrdersPage from "./pages/PurchaseOrders";
import AIPage from "./pages/AI";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Box, CircularProgress, Container } from "@mui/material";
import { IntlProvider, FormattedMessage } from "react-intl";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/sv";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import "./index.css";
import theme from "./theme";
import ReleasesPage from "./pages/Releases";
import languages, { DEFAULT_LANGUAGE } from "./languages";
import { GlobalIntlProvider } from "./GlobalIntlProvider";
import ProjectsPage from "./pages/Projects";
import CompaniesPage from "./pages/Companies";
import { getOrganizations } from "./redux/reducerSlices/organization";
import { Elements as StripeElementsProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getOwnedOrganizations } from "./redux/reducerSlices/owned-organization";
import { updateLayout } from "./redux/reducerSlices/layout";
import ExpensesPage from "./pages/Expenses";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const getMessages = (langCode: string = DEFAULT_LANGUAGE) => {
  return languages[langCode] || languages[DEFAULT_LANGUAGE];
};

const PrivateRoute: React.FC<{
  component: React.FC;
  exact: Boolean;
  path: string;
  allow: any[];
  needsOrg?: boolean;
}> = ({ component: Component, allow, path, needsOrg = true, ...rest }) => {
  const user = useSelector((state: any) => state.auth);

  const orgRole = String(user.selectedOrg?.role).toLocaleLowerCase();
  const subscriptionStatus = user.selectedOrg?.subscription_status;

  if (user.jwt && !user.id) {
    return (
      <Container>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "200px",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      </Container>
    );
  }

  if (needsOrg && !user.selectedOrg) {
    return (
      <Container>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormattedMessage
            id="general.You-need-to-select-an-organization-to-access-the-application"
            defaultMessage="You need to select an organization to access the application"
          />
        </Box>
      </Container>
    );
  }

  if (needsOrg && !["active", "past_due"].includes(subscriptionStatus)) {
    return (
      <Container>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormattedMessage
            id="general.Organization-is-currently-unavailable"
            defaultMessage="Organization is currently unavailable"
          />
        </Box>
      </Container>
    );
  }

  if (needsOrg && !allow.includes(orgRole)) {
    return (
      <Container>
        <Box
          sx={{
            mt: 2,
            height: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormattedMessage
            id="general.user-cannot-access-route"
            defaultMessage="Only users with administrator privileges can access {path} at the moment"
            values={{
              path: path,
            }}
          />
        </Box>
      </Container>
    );
  }

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (user.id) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

const AppRoutes: React.FC<{ logout: any }> = ({ logout }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!history.location.pathname.includes("print-quote")) {
      dispatch(
        updateLayout({
          disableAppBar: false,
        })
      );
    }
  }, [dispatch, history.location.pathname]);

  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route
        exact
        path="/redirect"
        component={() => <LoginRedirect logout={logout} />}
      />
      <PrivateRoute
        allow={["admin", "developer"]}
        exact
        path="/dashboard"
        component={() => <DashboardPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/projects"
        component={() => <ProjectsPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/quotes"
        component={() => <QuotesPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/invoices"
        component={() => <InvoicesPage />}
      />
      <PrivateRoute
        allow={["admin", "developer"]}
        exact
        path="/tasks*"
        component={() => <TasksDashboard />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/tickets"
        component={() => <TicketsPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/expenses"
        component={() => <ExpensesPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/releases"
        component={() => <ReleasesPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/companies"
        component={() => <CompaniesPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/purchase-orders"
        component={() => <PurchaseOrdersPage />}
      />
      <PrivateRoute
        allow={["admin"]}
        exact
        path="/subscriptions"
        component={() => <SubscriptionsPage />}
      />
      <PrivateRoute
        allow={["admin", "developer", "authenticated"]}
        needsOrg={false}
        exact
        path="/organizations"
        component={() => <OrganizationsPage />}
      />
      <PrivateRoute
        allow={["admin", "developer", "authenticated"]}
        needsOrg={false}
        exact
        path="/profile*"
        component={() => <ProfilePage />}
      />
      <PrivateRoute
        allow={["admin", "developer", "authenticated"]}
        exact
        path="/users"
        component={() => <UsersPage />}
      />
      <PrivateRoute
        allow={["admin", "developer", "authenticated"]}
        exact
        path="/ai"
        component={() => <AIPage />}
      />
    </Switch>
  );
};

const App = () => {
  const user = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();

  moment.locale(user.language);

  axios.defaults.baseURL = process.env.REACT_APP_baseUrl;
  axios.defaults.headers.common = { Authorization: `Bearer ${user.jwt}` };

  const logoutAuth0 = async () => {
    const resBuff = await fetch(
      process.env.REACT_APP_baseUrl + "/cs/get-auth0"
    );

    if (resBuff.status === 200) {
      const auth0 = await resBuff.json();

      localStorage.removeItem("user");

      window.location.href = `${auth0.server}/logout?client_id=${auth0.key}`;
    } else {
      const error = await resBuff.json();
      console.error("Error during logout: ", error);
    }
  };

  React.useEffect(() => {
    window.Trello.setKey(process.env.REACT_APP_TRELLO_KEY);
  }, []);

  React.useEffect(() => {
    if (user.id) {
      dispatch(getOrganizations());
      dispatch(getOwnedOrganizations());
    }
  }, [dispatch, user.id, user.selected_organization_id]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <IntlProvider
          locale={user?.language || "en"}
          defaultLocale="en"
          messages={getMessages(user?.language)}
        >
          <GlobalIntlProvider>
            <StripeElementsProvider stripe={stripePromise}>
              <Router>
                <CssBaseline />
                <Toaster />
                <AppBar logout={logoutAuth0} />
                <AppRoutes logout={logoutAuth0} />
              </Router>
            </StripeElementsProvider>
          </GlobalIntlProvider>
        </IntlProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
