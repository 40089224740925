import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles/quotePdfStyles';
import { Quote } from '../../../../components/task/types';
import { useIntl } from 'react-intl';
import { formatAddress } from '../../../../utils/addressFormatter';

interface PartiesSectionProps {
  quote: Quote | null;
}

export const PartiesSection: React.FC<PartiesSectionProps> = ({ quote }) => {
  const intl = useIntl();
  const partner = quote?.company?.invoice_to;
  const language = quote?.company?.language;

  // Format Gotologic's address
  const gotologicAddress = formatAddress({
    addressLine1: 'Palandergatan 17',
    city: 'Johanneshov',
    zipCode: '121 37',
    country: 'Sweden',
    language
  });

  // Format partner's address if available
  const partnerAddress = partner ? formatAddress({
    addressLine1: partner.addressLine1,
    addressLine2: partner.addressLine2,
    city: partner.city,
    zipCode: partner.zipCode,
    province: partner.province,
    country: partner.country,
    language
  }) : [];

  // Format client's address if available
  const clientAddress = quote?.company ? formatAddress({
    addressLine1: quote.company.addressLine1,
    addressLine2: quote.company.addressLine2,
    city: quote.company.city,
    zipCode: quote.company.zipCode,
    province: quote.company.province,
    country: quote.company.country,
    language
  }) : [];

  return (
    <View style={styles.section}>
      <View style={styles.sectionTitleGroup}>
        <Text style={styles.sectionTitle}>
          {intl.formatMessage({ id: 'quote.pdf.parties' })}
        </Text>
        <View style={styles.partiesGrid}>
          <View style={styles.partyBox}>
            <Text style={styles.partyTitle}>
              {intl.formatMessage({ id: 'quote.pdf.supplier' })}
            </Text>
            <Text style={styles.partyText}>Gotologic AB</Text>
            {gotologicAddress.map((line, index) => (
              <Text key={index} style={styles.partyText}>{line}</Text>
            ))}
          </View>
          {partner && (
            <View style={styles.partyBox}>
              <Text style={styles.partyTitle}>
                {intl.formatMessage({ id: 'quote.pdf.inPartnershipWith' })}
              </Text>
              <Text style={styles.partyText}>{partner.name}</Text>
              {partnerAddress.map((line, index) => (
                <Text key={index} style={styles.partyText}>{line}</Text>
              ))}
            </View>
          )}
          <View style={styles.partyBox}>
            <Text style={styles.partyTitle}>
              {intl.formatMessage({ id: 'quote.pdf.client' })}
            </Text>
            <Text style={styles.partyText}>{quote?.company?.name}</Text>
            {clientAddress.map((line, index) => (
              <Text key={index} style={styles.partyText}>{line}</Text>
            ))}
          </View>
        </View>
      </View>
    </View>
  );
};
