import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import userReducer from "./reducerSlices/user";
import taskReducer from "./reducerSlices/task";
import projectReducer from "./reducerSlices/project";
import ticketReducer from "./reducerSlices/ticket";
import quoteReducer from "./reducerSlices/quote";
import invoiceReducer from "./reducerSlices/invoice";
import purchaseOrderReducer from "./reducerSlices/purchase-order";
import subscriptionReducer from "./reducerSlices/subscription";
import releaseReducer from "./reducerSlices/release";
import trelloReducer from "./reducerSlices/trello";
import upworkReducer from "./reducerSlices/upwork";
import fortnoxReducer from "./reducerSlices/fortnox";
import usersReducers from "./reducerSlices/users";
import roleReducer from "./reducerSlices/role";
import companyReducer from "./reducerSlices/company";
import organizationReducer from "./reducerSlices/organization";
import inviteReducer from "./reducerSlices/invite";
import ownedOrganizationReducer from "./reducerSlices/owned-organization";
import meReducer from "./reducerSlices/me";
import layoutReducer from "./reducerSlices/layout";
import dashboardReducer from "./reducerSlices/dashboard";
import expenseReducer from "./reducerSlices/expense";
import conversationReducer from "./reducerSlices/conversation";

const reducer = combineReducers({
  auth: userReducer,
  user: meReducer,
  task: taskReducer,
  project: projectReducer,
  ticket: ticketReducer,
  quotes: quoteReducer,
  releases: releaseReducer,
  invoices: invoiceReducer,
  purchaseOrderState: purchaseOrderReducer,
  subscriptionState: subscriptionReducer,
  trello: trelloReducer,
  upwork: upworkReducer,
  fortnox: fortnoxReducer,
  userState: usersReducers,
  roles: roleReducer,
  companyState: companyReducer,
  organizations: organizationReducer,
  inviteState: inviteReducer,
  ownedOrganizationState: ownedOrganizationReducer,
  layout: layoutReducer,
  dashboard: dashboardReducer,
  expenses: expenseReducer,
  conversation: conversationReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
