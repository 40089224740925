import { useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { FormattedMessage } from "react-intl";
import { Box, IconButton } from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';
import EmailModal from './EmailModal';

interface InvoiceProps {
  number: number;
  status: string;
  id?: string;
  fortnox_id?: string;
  handleOpenEdinModal: (invoice: any) => void;
  company: {
    name: string;
  };
  date: string;
}

const Row: React.FC<{ invoice: InvoiceProps }> = ({ invoice }) => {
  const { number, status, handleOpenEdinModal, company, date, fortnox_id, id } = invoice;
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  
  const handleEditQuote = () => {
    handleOpenEdinModal(invoice);
  };

  const handlePdfPreview = async (event: React.MouseEvent) => {
    event.stopPropagation();
    
    if (fortnox_id && id) {
      try {
        const response = await axios.get(`/fortnox/invoice-pdf/${id}`, {
          responseType: 'blob'
        });
        
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    }
  };

  const handleSendClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setEmailModalOpen(true);
  };

  const renderStatus = () => {
    switch (status) {
      case "draft":
        return (
          <FormattedMessage id="general-status.draft" defaultMessage="draft" />
        );
      case "sent":
        return (
          <FormattedMessage id="general-status.sent" defaultMessage="sent" />
        );
      case "closed":
        return (
          <FormattedMessage
            id="general-status.closed"
            defaultMessage="closed"
          />
        );
      default:
        console.error("unexpected status: ", status);
        return (
          <FormattedMessage
            id="general-status.unexpected-status"
            defaultMessage="unexpected status"
          />
        );
    }
  };

  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
        }}
        style={{ cursor: "pointer" }}
        onClick={handleEditQuote}
      >
        <TableCell component="th" scope="row">
          #{number}
        </TableCell>
        <TableCell component="th" scope="row">
          {company?.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {renderStatus()}
        </TableCell>
        <TableCell component="th" scope="row">
          {date}
        </TableCell>
        <TableCell component="th" scope="row">
          <Box display="flex" justifyContent="flex-end">
            {fortnox_id && (
              <>
                <IconButton onClick={handlePdfPreview}>
                  <PrintIcon />
                </IconButton>
                <IconButton onClick={handleSendClick}>
                  <SendIcon />
                </IconButton>
              </>
            )}
          </Box>
        </TableCell>
      </TableRow>
      <EmailModal
        open={emailModalOpen}
        onClose={() => setEmailModalOpen(false)}
        invoiceId={id?.toString()}
      />
    </>
  );
};

export default Row;
