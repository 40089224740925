import React from 'react';
import { Page, View, Text, Image } from '@react-pdf/renderer';
import { styles } from '../styles/quotePdfStyles';
import { BackgroundSymbol } from './BackgroundSymbol';
import { Quote } from '../../../components/task/types';
import { getProjectName } from '../utils/helpers';
import logo from '../../../assets/img/logo-for-pdf.png';
import softwareDevImage from '../../../assets/img/software-development.jpg';
import { useIntl } from 'react-intl';
import { getProxiedImageUrl } from '../../../utils/imageProxy';

interface FirstPageProps {
  quote: Quote | null;
}

export const FirstPage: React.FC<FirstPageProps> = ({ quote }) => {
  const intl = useIntl();
  const clientLogoUrl = quote?.company?.invoice_to?.logo?.url;

  return (
    <Page size="A4" style={styles.firstPage}>
      <BackgroundSymbol />
      <View style={styles.firstPageContent}>
        <View style={styles.firstPageLogoContainer}>
          <Image src={logo} style={styles.firstPageLogo} />
          {clientLogoUrl && (
            <Image 
              src={getProxiedImageUrl(clientLogoUrl)} 
              style={styles.firstPageClientLogo}
            />
          )}
        </View>
        <Text style={styles.firstPageProjectName}>
          {getProjectName(quote).toUpperCase()}
        </Text>
        <Text style={styles.firstPageQuoteNumber}>
          {intl.formatMessage(
            { id: 'quote.pdf.title' },
            { quote_number: quote?.number }
          )}
        </Text>
      </View>
      <Image src={softwareDevImage} style={styles.bottomImage} />
    </Page>
  );
};
