import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

import { getDashboardData } from "../../redux/reducerSlices/dashboard";
import QuoteModal from "../../components/quote/modal/QuoteModal";
import { Invoice, Quote } from "../../components/task/types";
import ReleaseModal from "../../components/release/modal";
import { Release } from "../../components/release/types";
import { getProjectOptions } from "../../redux/reducerSlices/project";
import InvoiceModal from "../../components/invoice/modal";
import Card from "./Card";
import SubscriptionModal from "../../components/subscription/modal";
import moment from "moment";

window.moment = moment;

const Dashboard = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const {
    dashboard: {
      quotesReadyToSent,
      quotesToAddPrice,
      projectsToRelease,
      projectsReleasePending,
      invoicesToSend,
      tasksToReview,
      tasksToQuote,
      tasksToRelease,
      tasksToInvoice,
      subscriptionsToInvoice,
      tasksToEstimate,
      tasksInProgress,
      tasksToDo,
      subscriptionRenewalNotice,
      expiringQuotes,
    },
    user,
  } = useSelector((state: RootStateOrAny) => state);
  const isAdmin = user?.selected_organization_role === "Admin";

  const [openQuoteModal, setQuoteOpenModal] = useState<boolean>(false);
  const [modalQuote, setModalQuote] = useState<Quote | null>(null);
  const [openReleaseModal, setOpenReleaseModal] = useState<boolean>(false);
  const [modalRelease, setModalRelease] = useState<Release | null>(null);
  const [modalInvoice, setModalInvoice] = useState<Invoice | null>(null);
  const [openInvoiceModal, setOpenInvoiceModal] = useState<boolean>(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] =
    useState<boolean>(false);
  const [modalSubscription, setModalSubscription] = useState<any>(null);

  useEffect(() => {
    dispatch(getProjectOptions());
    dispatch(
      getDashboardData({
        tasksToReview: true,
        tasksToQuote: true,
        quotesReadyToSent: true,
        quotesToAddPrice: true,
        projectsToRelease: true,
        projectsReleasePending: true,
        tasksToRelease: true,
        tasksToInvoice: true,
        invoicesToSend: true,
        subscriptionsToInvoice: true,
        tasksToEstimate: true,
        tasksInProgress: true,
        tasksToDo: true,
        subscriptionRenewalNotice: true,
        expiringQuotes: true,
      })
    );
  }, [dispatch]);

  const joinNames = (names: string[]) => {
    return names.join(", ").replace(
      /,(?!.*,)/gim,
      ` ${intl.formatMessage({
        id: "general.and",
        defaultMessage: "and",
      })}`
    );
  };

  const renderTasksToReview = () => {
    const projects = tasksToReview.map((task: any) => task.project_name);
    const projectsString = joinNames(projects);
    const tasksCount = tasksToReview.reduce(
      (acc: any, task: any) => acc + Number(task.tasks_count),
      0
    );

    if (!tasksCount) {
      return null;
    }

    let description = (
      <FormattedMessage
        id="general.Projects-have-tasks-in-review"
        defaultMessage="Projects {projects} have {tasksCount} tasks in review."
        values={{
          projects: <strong>{projectsString}</strong>,
          tasksCount: <strong>{tasksCount}</strong>,
        }}
      />
    );

    if (tasksCount === 1) {
      description = (
        <FormattedMessage
          id="general.Project-has-task-in-review"
          defaultMessage="Project {projects} has {tasksCount} task in review."
          values={{
            projects: <strong>{projectsString}</strong>,
            tasksCount: <strong>{tasksCount}</strong>,
          }}
        />
      );
    } else if (projects.length === 1) {
      description = (
        <FormattedMessage
          id="general.Project-has-tasks-in-review"
          defaultMessage="Project {projects} has {tasksCount} task in review."
          values={{
            projects: <strong>{projectsString}</strong>,
            tasksCount: <strong>{tasksCount}</strong>,
          }}
        />
      );
    }

    const data = {
      title: intl.formatMessage({
        id: "general.Tasks-to-review",
        defaultMessage: "Tasks to review",
      }),
      description,
      onClick: () => history.push("/tasks/review"),
    };

    return <Card {...data} icon={<AssignmentTurnedInIcon />} />;
  };

  const renderTasksToQuote = () => {
    const projects = tasksToQuote.map((task: any) => task.project_name);
    const projectsString = joinNames(projects);
    const tasksCount = tasksToQuote.reduce(
      (acc: any, task: any) => acc + Number(task.tasks_count),
      0
    );

    if (!tasksCount) {
      return null;
    }

    let description = (
      <FormattedMessage
        id="general.Projects-have-tasks-in-quote"
        defaultMessage="Projects {projects} have {tasksCount} tasks that need to be added to a quote."
        values={{
          projects: <strong>{projectsString}</strong>,
          tasksCount: <strong>{tasksCount}</strong>,
        }}
      />
    );

    if (tasksCount === 1) {
      description = (
        <FormattedMessage
          id="general.Project-has-task-in-quote"
          defaultMessage="Project {projects} has {tasksCount} task that need to be added to a quote."
          values={{
            projects: <strong>{projectsString}</strong>,
            tasksCount: <strong>{tasksCount}</strong>,
          }}
        />
      );
    } else if (projects.length === 1) {
      description = (
        <FormattedMessage
          id="general.Project-has-tasks-in-quote"
          defaultMessage="Project {projects} has {tasksCount} tasks that need to be added to a quote."
          values={{
            projects: <strong>{projectsString}</strong>,
            tasksCount: <strong>{tasksCount}</strong>,
          }}
        />
      );
    }

    const data = {
      title: intl.formatMessage({
        id: "general.Tasks-to-quote",
        defaultMessage: "Tasks to quote",
      }),
      description,
      onClick: () => history.push("/tasks/add-to-quote"),
    };

    return <Card {...data} icon={<RequestQuoteIcon />} />;
  };

  const renderTasksToRelease = () => {
    const projects = tasksToRelease.map((task: any) => task.project_name);
    const projectsString = joinNames(projects);
    const tasksCount = tasksToRelease.reduce(
      (acc: any, task: any) => acc + Number(task.tasks_count),
      0
    );

    if (!tasksCount) return null;

    let description = (
      <FormattedMessage
        id="general.Projects-{projects}-have-{tasksCount}-completed-tasks.-Add-them-to-a-release."
        defaultMessage="Projects {projects} have {tasksCount} completed tasks. Add them to a release."
        values={{
          projects: <strong>{projectsString}</strong>,
          tasksCount: <strong>{tasksCount}</strong>,
        }}
      />
    );

    if (tasksCount === 1) {
      description = (
        <FormattedMessage
          id="general.Project-{projects}-has-{tasksCount}-completed-task.-Add-it-to-a-release."
          defaultMessage="Project {projects} has {tasksCount} completed task. Add it to a release."
          values={{
            projects: <strong>{projectsString}</strong>,
            tasksCount: <strong>{tasksCount}</strong>,
          }}
        />
      );
    } else if (projects.length > 1) {
      description = (
        <FormattedMessage
          id="general.Project-{projects}-have-{tasksCount}-completed-tasks.-Add-them-to-a-release."
          defaultMessage="Project {projects} have {tasksCount} completed tasks. Add them to a release."
          values={{
            projects: <strong>{projectsString}</strong>,
            tasksCount: <strong>{tasksCount}</strong>,
          }}
        />
      );
    }

    const data = {
      title: intl.formatMessage({
        id: "general.Tasks-to-release",
        defaultMessage: "Tasks to release",
      }),
      description,
      onClick: () => history.push("/tasks/add-to-release"),
    };

    return <Card {...data} icon={<NewReleasesIcon />} />;
  };

  const renderTasksToInvoice = () => {
    const projectsMap = tasksToInvoice.reduce((acc: any, task: any) => {
      if (!acc[task.project?.name]) {
        acc[task.project?.name] = 0;
      }
      acc[task.project?.name] += 1;
      return acc;
    }, {});
    const projects = Object.keys(projectsMap);
    const projectsString = joinNames(projects);
    const tasksCount = Object.values(projectsMap).reduce(
      (acc: any, task: any) => acc + Number(task),
      0
    );

    if (!tasksCount) return null;

    let description = (
      <FormattedMessage
        id="general.There-are-{tasksCount}-tasks-for-projects-{projects}-that-need-invoicing."
        defaultMessage="There are {tasksCount} tasks for projects {projects} that need invoicing."
        values={{
          projects: <strong>{projectsString}</strong>,
          tasksCount: <strong>{tasksCount}</strong>,
        }}
      />
    );

    if (tasksCount === 1) {
      description = (
        <FormattedMessage
          id="general.There-is-{tasksCount}-task-for-project-{projects}-that-need-invoicing."
          defaultMessage="There is {tasksCount} task for project {projects} that need invoicing."
          values={{
            projects: <strong>{projectsString}</strong>,
            tasksCount: <strong>{tasksCount}</strong>,
          }}
        />
      );
    } else if (projects.length === 1) {
      description = (
        <FormattedMessage
          id="general.There-are-{tasksCount}-tasks-for-project-{projects}-that-need-invoicing."
          defaultMessage="There are {tasksCount} tasks for project {projects} that need invoicing."
          values={{
            projects: <strong>{projectsString}</strong>,
            tasksCount: <strong>{tasksCount}</strong>,
          }}
        />
      );
    }

    const data = {
      title: intl.formatMessage({
        id: "general.Tasks-to-invoice",
        defaultMessage: "Tasks to invoice",
      }),
      description,
      onClick: () => history.push("/tasks/add-to-invoice"),
    };

    return <Card {...data} icon={<ReceiptIcon />} />;
  };

  const renderSubscriptionsToInvoice = () => {
    const subTitles = subscriptionsToInvoice.map(
      (subscription: any) => subscription.title
    );
    const subscriptionsString = joinNames(subTitles);

    if (!subscriptionsToInvoice.length) return null;

    let description = (
      <FormattedMessage
        id="general.Subscriptions-to-invoice-description"
        defaultMessage="Subscriptions {subscriptionsString} need invoicing."
        values={{
          subscriptionsString: <strong>{subscriptionsString}</strong>,
        }}
      />
    );

    if (subscriptionsToInvoice.length === 1) {
      description = (
        <FormattedMessage
          id="general.Subscription-to-invoice-description"
          defaultMessage="Subscription {subscriptionsString} needs invoicing."
          values={{
            subscriptionsString: <strong>{subscriptionsString}</strong>,
          }}
        />
      );
    }

    const data = {
      title: intl.formatMessage({
        id: "general.Subscriptions-to-invoice",
        defaultMessage: "Subscriptions to invoice",
      }),
      description,
      onClick: () => history.push("/tasks/add-to-invoice"),
    };

    return <Card {...data} icon={<LoyaltyIcon />} />;
  };

  const renderTasksToEstimate = (onlyAssigned: boolean = false) => {
    const projectsMap = tasksToEstimate
      .filter((task: any) => {
        if (onlyAssigned) {
          return task.developer?.id === user.id;
        }

        return true;
      })
      .reduce((acc: any, task: any) => {
        if (!acc[task.project?.name]) {
          acc[task.project?.name] = 0;
        }
        acc[task.project?.name] += 1;
        return acc;
      }, {});
    const projects = Object.keys(projectsMap);
    const projectsString = joinNames(projects);
    const tasksCount = Object.values(projectsMap).reduce(
      (acc: any, task: any) => acc + Number(task),
      0
    );

    if (!tasksCount) return null;

    let description = (
      <FormattedMessage
        id="general.Projects-{projects}-have-{tasksCount}-tasks-to-estimate."
        defaultMessage="Projects {projects} have {tasksCount} tasks to estimate."
        values={{
          projects: <strong>{projectsString}</strong>,
          tasksCount: <strong>{tasksCount}</strong>,
        }}
      />
    );

    if (tasksCount === 1) {
      description = (
        <FormattedMessage
          id="general.Project-{projects}-has-{tasksCount}-task-to-estimate."
          defaultMessage="Project {projects} has {tasksCount} task to estimate."
          values={{
            projects: <strong>{projectsString}</strong>,
            tasksCount: <strong>{tasksCount}</strong>,
          }}
        />
      );
    } else if (projects.length === 1) {
      description = (
        <FormattedMessage
          id="general.Project-{projects}-has-{tasksCount}-tasks-to-estimate."
          defaultMessage="Project {projects} has {tasksCount} tasks to estimate."
          values={{
            projects: <strong>{projectsString}</strong>,
            tasksCount: <strong>{tasksCount}</strong>,
          }}
        />
      );
    }

    const data = {
      title: intl.formatMessage({
        id: onlyAssigned
          ? "general.My-tasks-to-estimate"
          : "general.Tasks-to-estimate",
        defaultMessage: onlyAssigned
          ? "My tasks to estimate"
          : "Tasks to estimate",
      }),
      description,
      onClick: () =>
        history.push(
          onlyAssigned ? "/tasks/my-to-estimate" : "/tasks/to-estimate"
        ),
    };

    return <Card {...data} icon={<AccessTimeFilledIcon />} />;
  };

  const renderTasksInProgress = (onlyAssigned: boolean = false) => {
    const projectsMap = tasksInProgress
      .filter((task: any) => {
        if (onlyAssigned) {
          return task.developer?.id === user.id;
        }

        return true;
      })
      .reduce((acc: any, task: any) => {
        if (!acc[task.project?.name]) {
          acc[task.project?.name] = 0;
        }
        acc[task.project?.name] += 1;
        return acc;
      }, {});
    const projects = Object.keys(projectsMap);
    const projectsString = joinNames(projects);
    const tasksCount = Object.values(projectsMap).reduce(
      (acc: any, task: any) => acc + Number(task),
      0
    );

    if (!tasksCount) return null;

    let description = (
      <FormattedMessage
        id="general.Projects-{projects}-have-{tasksCount}-tasks-in-progress."
        defaultMessage="Projects {projects} have {tasksCount} tasks in progress."
        values={{
          projects: <strong>{projectsString}</strong>,
          tasksCount: <strong>{tasksCount}</strong>,
        }}
      />
    );

    if (tasksCount === 1) {
      description = (
        <FormattedMessage
          id="general.Project-{projects}-has-{tasksCount}-task-in-progress."
          defaultMessage="Project {projects} has {tasksCount} task in progress."
          values={{
            projects: <strong>{projectsString}</strong>,
            tasksCount: <strong>{tasksCount}</strong>,
          }}
        />
      );
    } else if (projects.length === 1) {
      description = (
        <FormattedMessage
          id="general.Project-{projects}-has-{tasksCount}-tasks-in-progress."
          defaultMessage="Project {projects} has {tasksCount} tasks in progress."
          values={{
            projects: <strong>{projectsString}</strong>,
            tasksCount: <strong>{tasksCount}</strong>,
          }}
        />
      );
    }

    const data = {
      title: intl.formatMessage({
        id: onlyAssigned
          ? "general.My-tasks-in-progress"
          : "general.Tasks-in-progress",
        defaultMessage: onlyAssigned
          ? "My tasks in progress"
          : "Tasks in progress",
      }),
      description,
      onClick: () =>
        history.push(
          onlyAssigned ? "/tasks/my-in-progress" : "/tasks/in-progress"
        ),
    };

    return <Card {...data} icon={<AssignmentTurnedInIcon />} />;
  };

  const renderTasksToDo = (onlyAssigned: boolean = false) => {
    const projectsMap = tasksToDo
      .filter((task: any) => {
        if (onlyAssigned) {
          return task.developer?.id === user.id;
        }

        return true;
      })
      .reduce((acc: any, task: any) => {
        if (!acc[task.project?.name]) {
          acc[task.project?.name] = 0;
        }
        acc[task.project?.name] += 1;
        return acc;
      }, {});
    const projects = Object.keys(projectsMap);
    const projectsString = joinNames(projects);
    const tasksCount = Object.values(projectsMap).reduce(
      (acc: any, task: any) => acc + Number(task),
      0
    );

    if (!tasksCount) return null;

    let description = (
      <FormattedMessage
        id="general.Projects-{projects}-have-{tasksCount}-tasks-to-do."
        defaultMessage="Projects {projects} have {tasksCount} tasks to do."
        values={{
          projects: <strong>{projectsString}</strong>,
          tasksCount: <strong>{tasksCount}</strong>,
        }}
      />
    );

    if (tasksCount === 1) {
      description = (
        <FormattedMessage
          id="general.Project-{projects}-has-{tasksCount}-task-to-do."
          defaultMessage="Project {projects} has {tasksCount} task to do."
          values={{
            projects: <strong>{projectsString}</strong>,
            tasksCount: <strong>{tasksCount}</strong>,
          }}
        />
      );
    } else if (projects.length === 1) {
      description = (
        <FormattedMessage
          id="general.Project-{projects}-has-{tasksCount}-tasks-to-do."
          defaultMessage="Project {projects} has {tasksCount} tasks to do."
          values={{
            projects: <strong>{projectsString}</strong>,
            tasksCount: <strong>{tasksCount}</strong>,
          }}
        />
      );
    }

    const data = {
      title: intl.formatMessage({
        id: onlyAssigned ? "general.My-tasks-to-do" : "general.Tasks-to-do",
        defaultMessage: onlyAssigned ? "My tasks to do" : "Tasks to do",
      }),
      description,
      onClick: () =>
        history.push(onlyAssigned ? "/tasks/my-to-do" : "/tasks/to-do"),
    };

    return <Card {...data} icon={<AssignmentTurnedInIcon />} />;
  };

  return (
    <Container className="main-dashboard">
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage
              id="general.Dashboard"
              defaultMessage="Dashboard"
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            mb: 2,
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          {renderTasksToReview()}
          {renderTasksToQuote()}
          {quotesReadyToSent.map((quote: any) => {
            return (
              <Card
                key={quote.id}
                title={intl.formatMessage(
                  {
                    id: "general.Quote-number-ready-to-be-sent",
                    defaultMessage: "Quote {number} ready to be sent",
                  },
                  {
                    number: <strong>{"#" + quote.number}</strong>,
                  }
                )}
                icon={<RequestQuoteIcon />}
                description={
                  <FormattedMessage
                    id="general.All-the-items-on-quote-number-have-a-price"
                    defaultMessage="All the items on quote {number} have a price. Review it and send it to {companyName}."
                    values={{
                      number: <strong>{"#" + quote.number}</strong>,
                      companyName: <strong>{quote.company.name}</strong>,
                    }}
                  />
                }
                onClick={() => {
                  setModalQuote(quote);
                  setQuoteOpenModal(true);
                }}
              />
            );
          })}
          {quotesToAddPrice.map((quote: any) => {
            return (
              <Card
                key={quote.id}
                title={intl.formatMessage(
                  {
                    id: "general.Quote-number-pending",
                    defaultMessage: "Quote {number} pending",
                  },
                  {
                    number: <strong>{"#" + quote.number}</strong>,
                  }
                )}
                icon={<RequestQuoteIcon />}
                description={
                  <FormattedMessage
                    id="general.Some-items-on-quote-number-for-client-dont-have-a-price-yet"
                    defaultMessage="Some items on quote {number} for {client} don't have a price yet."
                    values={{
                      number: <strong>{"#" + quote.number}</strong>,
                      client: <strong>{quote.company.name}</strong>,
                    }}
                  />
                }
                onClick={() => {
                  setModalQuote(quote);
                  setQuoteOpenModal(true);
                }}
              />
            );
          })}
          {expiringQuotes.map((quote: any) => {
            const daysToExpire = moment(quote.expiration_date)
              .add(1, "days")
              .diff(moment(), "days");

            return (
              <Card
                key={quote.id}
                title={intl.formatMessage(
                  {
                    id: "general.Quote-number-for-expiring-soon",
                    defaultMessage: "Quote {number} for {client} expiring soon",
                  },
                  {
                    number: <strong>{"#" + quote.number}</strong>,
                    client: <strong>{quote.company.name}</strong>,
                  }
                )}
                icon={<RequestQuoteIcon />}
                description={
                  <FormattedMessage
                    id="general.This-quote-will-expire-in-N-days-follow-up-with-the-client-or-update-its-status."
                    defaultMessage="This quote will expire in {days} days, follow up with the client or update its status."
                    values={{
                      days: <strong>{daysToExpire}</strong>,
                    }}
                  />
                }
                onClick={() => {
                  setModalQuote(quote);
                  setQuoteOpenModal(true);
                }}
              />
            );
          })}
          {projectsToRelease.map((release: any) => {
            return (
              <Card
                key={release.id}
                title={intl.formatMessage(
                  {
                    id: "general.{projectName} ready for release",
                    defaultMessage: "{projectName} ready for release",
                  },
                  {
                    projectName: <strong>{release.project?.name}</strong>,
                  }
                )}
                icon={<NewReleasesIcon />}
                description={
                  <FormattedMessage
                    id="general.All-the-tasks-in-release-{releaseId}-of-{projectName}-are-completed.-Review-it-and-deploy-it-if-ready."
                    defaultMessage="All the tasks in release {releaseId} of {projectName} are completed. Review it and deploy it if ready."
                    values={{
                      releaseId: <strong>{release.id}</strong>,
                      projectName: <strong>{release.project?.name}</strong>,
                    }}
                  />
                }
                onClick={() => {
                  setModalRelease(release);
                  setOpenReleaseModal(true);
                }}
              />
            );
          })}
          {projectsReleasePending.map((release: any) => {
            const { id, project } = release;

            return (
              <Card
                key={id}
                title={intl.formatMessage(
                  {
                    id: "general.{projectName}-release-pending",
                    defaultMessage: "{projectName} release pending",
                  },
                  {
                    projectName: <strong>{project?.name}</strong>,
                  }
                )}
                icon={<NewReleasesIcon />}
                description={
                  <FormattedMessage
                    id="general.Some-tasks-in-release-{releaseId}-of-{projectName}-aren't-ready-yet."
                    defaultMessage="Some tasks in release {releaseId} of {projectName} aren't ready yet."
                    values={{
                      releaseId: <strong>{id}</strong>,
                      projectName: <strong>{project?.name}</strong>,
                    }}
                  />
                }
                onClick={() => {
                  setModalRelease(release);
                  setOpenReleaseModal(true);
                }}
              />
            );
          })}
          {renderTasksToRelease()}
          {renderTasksToInvoice()}
          {invoicesToSend.map((invoice: any) => {
            const client = invoice.company.name;

            return (
              <Card
                key={invoice.id}
                title={intl.formatMessage(
                  {
                    id: "general.Invoice-number-pending-for-client-pending",
                    defaultMessage: "Invoice {number} for {client} pending",
                  },
                  {
                    number: <strong>{"#" + invoice.number}</strong>,
                    client: <strong>{client}</strong>,
                  }
                )}
                icon={<ReceiptIcon />}
                description={
                  <FormattedMessage
                    id="general.This-invoice-has-been-started-and-needs-to-be-sent"
                    defaultMessage="This invoice has been started and needs to be sent."
                  />
                }
                onClick={() => {
                  setModalInvoice(invoice);
                  setOpenInvoiceModal(true);
                }}
              />
            );
          })}
          {renderSubscriptionsToInvoice()}
          {renderTasksToEstimate()}
          {isAdmin && renderTasksToEstimate(true)}
          {renderTasksInProgress()}
          {isAdmin && renderTasksInProgress(true)}
          {renderTasksToDo()}
          {isAdmin && renderTasksToDo(true)}
          {subscriptionRenewalNotice.map((subscription: any) => {
            const { id, title, company, notice_until, next_invoice_date } =
              subscription;
            const client = company.name;
            const daysLeft = moment(next_invoice_date)
              .add(2, "days")
              .diff(moment(), "days");
            const untilDate = moment(notice_until)
              .toDate()
              .toLocaleDateString();

            return (
              <Card
                key={id}
                title={intl.formatMessage(
                  {
                    id: "general.{subscriptionName} renewing in {daysLeft} days",
                    defaultMessage:
                      "{subscriptionName} renewing in {daysLeft} days",
                  },
                  {
                    subscriptionName: <strong>{title}</strong>,
                    daysLeft: <strong>{daysLeft}</strong>,
                  }
                )}
                icon={<LoyaltyIcon />}
                description={
                  <FormattedMessage
                    id="general.Subscription-{subscriptionName}-for-{client}-renews-on-{date}.-You-have-until-{untilDate}-to-notify-them-of-any-change-in-terms."
                    defaultMessage="Subscription {subscriptionName} for {client} renews on {date}. You have until {untilDate} to notify them of any change in terms."
                    values={{
                      subscriptionName: <strong>{title}</strong>,
                      client: <strong>{client}</strong>,
                      date: (
                        <strong>
                          {moment(next_invoice_date)
                            .add(1, "days")
                            .toDate()
                            .toLocaleDateString()}
                        </strong>
                      ),
                      untilDate: <strong>{untilDate}</strong>,
                    }}
                  />
                }
                onClick={() => {
                  setModalSubscription(subscription);
                  setOpenSubscriptionModal(true);
                }}
              />
            );
          })}
        </Box>
      </Box>
      <QuoteModal
        openModal={openQuoteModal}
        setOpenModal={() => {
          setQuoteOpenModal(false);
          setModalQuote(null);
        }}
        extQuote={modalQuote}
        onSubmitCB={() => {
          dispatch(
            getDashboardData({
              quotesReadyToSent: true,
              quotesToAddPrice: true,
              expiringQuotes: true,
            })
          );
        }}
      />
      <ReleaseModal
        openModal={openReleaseModal}
        setOpenModal={setOpenReleaseModal}
        extRelease={modalRelease}
        onSubmitCB={() => {
          dispatch(
            getDashboardData({
              projectsToRelease: true,
            })
          );
        }}
      />
      <InvoiceModal
        openModal={openInvoiceModal}
        setOpenModal={() => {
          setOpenInvoiceModal(false);
          setModalInvoice(null);
        }}
        extInvoice={modalInvoice}
        onSubmitCB={() => {
          dispatch(
            getDashboardData({
              invoicesToSend: true,
            })
          );
        }}
      />
      <SubscriptionModal
        openModal={openSubscriptionModal}
        setOpenModal={() => {
          setOpenSubscriptionModal(false);
          setModalSubscription(null);
        }}
        extSubscription={modalSubscription}
        onSubmitCB={() => {
          dispatch(
            getDashboardData({
              subscriptionRenewalNotice: true,
            })
          );
        }}
      />
    </Container>
  );
};

export default Dashboard;
