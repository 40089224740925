import PostalAddress from 'i18n-postal-address';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import sv from 'i18n-iso-countries/langs/sv.json';
import fr from 'i18n-iso-countries/langs/fr.json';

// Register the languages we support
countries.registerLocale(en);
countries.registerLocale(sv);
countries.registerLocale(fr);

interface AddressComponents {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  zipCode?: string;
  province?: string;
  country?: string;
  language?: string;
}

export const formatAddress = (components: AddressComponents): string[] => {
  const address = new PostalAddress();

  if (components.addressLine1) address.setAddress1(components.addressLine1);
  if (components.addressLine2) address.setAddress2(components.addressLine2);
  if (components.city) address.setCity(components.city);
  if (components.province) address.setProvince(components.province);
  if (components.zipCode) address.setPostalCode(components.zipCode.replace(/ /g, '\u00A0'));

  if (components.country) {
    const codes = countries.getAlpha2Code(components.country, 'en');
    const countryCode = codes || components.country;
    address.setCountry(components.country);
    address.setFormat({country: countryCode})
  }

  const lines = address.toString().split('\n').filter(Boolean);

  // Replace the last line (country) with localized version if possible
  if (components.country && components.language && lines.length > 0) {
    const codes = countries.getAlpha2Code(components.country, 'en');
    if (codes) {
      const localizedCountry = countries.getName(codes, components.language);
      if (localizedCountry) {
        lines[lines.length - 1] = localizedCountry;
      }
    }
  }

  return lines;
};
