import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles/quotePdfStyles';
import { Quote } from '../../../../components/task/types';
import { useIntl } from 'react-intl';
import { getProjectName } from '../../utils/helpers';
import { MarkdownPDF } from '../../MarkdownPDF';

interface ContextSectionProps {
  quote: Quote | null;
}

export const ContextSection: React.FC<ContextSectionProps> = ({ quote }) => {
  const intl = useIntl();
  const projectName = getProjectName(quote);

  // If there's a custom body, use it with projectName substitution and uppercase level 1 headings
  // Otherwise, use the default context text from translations
  const content = quote?.body
    ? quote.body
        .replace(/\${projectName}/g, projectName)
        // Convert level 1 headings to uppercase, but keep the content after it unchanged
        .replace(/^# (.*)$/gm, (match, heading) => `# ${heading.toUpperCase()}`)
    : intl.formatMessage(
        { id: 'quote.pdf.contextText' },
        { projectName }
      );

  return (
    <View style={styles.section}>
      <View wrap={false}>
        {!quote?.body && (
          <Text style={styles.sectionTitle}>
            {intl.formatMessage({ id: 'quote.pdf.context' })}
          </Text>
        )}
        <MarkdownPDF content={content} />
      </View>
    </View>
  );
};
