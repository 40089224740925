import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { handleFortnoxCallback } from "../../../redux/reducerSlices/fortnox";

const FortnoxCallback = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");

    if (code) {
      dispatch(handleFortnoxCallback(code));
    }

    // Always redirect back to integrations page
    history.push("/profile/integrations");
  }, [dispatch, location, history]);

  return null;
};

export default FortnoxCallback;
