import React from 'react';
import { Box } from '@mui/material';

export const TypingIndicator = () => (
  <Box
    sx={{
      display: 'flex',
      gap: 0.5,
      p: 2,
      alignItems: 'center'
    }}
  >
    <Box
      component="span"
      sx={{
        width: 6,
        height: 6,
        borderRadius: '50%',
        backgroundColor: 'grey.400',
        animation: 'typing 1s infinite',
        '&:nth-of-type(2)': {
          animationDelay: '0.2s'
        },
        '&:nth-of-type(3)': {
          animationDelay: '0.4s'
        },
        '@keyframes typing': {
          '0%, 100%': {
            transform: 'translateY(0)'
          },
          '50%': {
            transform: 'translateY(-4px)'
          }
        }
      }}
    />
    <Box
      component="span"
      sx={{
        width: 6,
        height: 6,
        borderRadius: '50%',
        backgroundColor: 'grey.400',
        animation: 'typing 1s infinite',
        animationDelay: '0.2s'
      }}
    />
    <Box
      component="span"
      sx={{
        width: 6,
        height: 6,
        borderRadius: '50%',
        backgroundColor: 'grey.400',
        animation: 'typing 1s infinite',
        animationDelay: '0.4s'
      }}
    />
  </Box>
);
