import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useIntl } from "react-intl";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import moment from "moment";

import QuoteForm from "./components/QuoteForm";
import QuoteItems from "./components/QuoteItems";
import ConfirmDialog from "../../ConfirmDialog";
import CreateTask from "../../task/CreateTask";
import QuoteItemModal from "../itemModal";
import {
  Quote,
  QuoteStatus,
  Task,
  Client,
  QuoteItem,
  QuoteItemStatus,
} from "./types";
import { emptyQuote } from "./utils";
import {
  createQuote,
  deleteQuote,
  deleteQuoteItem,
  updateQuote,
} from "../../../redux/reducerSlices/quote";
import { refreshTasks } from "../../../redux/reducerSlices/task";
import { addTaskToCompany, getCompanies } from "../../../redux/reducerSlices/company";

interface QuoteModalProps {
  extQuote?: Quote | null;
  task?: Task | null;
  setOpenModal: (open: boolean) => void;
  openModal: boolean;
  company?: Client | null;
  onSubmitCB?: () => void;
}

const QuoteModal: React.FC<QuoteModalProps> = ({
  extQuote,
  task,
  setOpenModal,
  openModal,
  company,
  onSubmitCB,
}) => {
  const hasTask = Boolean(task);
  const isEditingQuote = Boolean(extQuote);
  const {
    project: { projectOptions },
    user,
    companyState,
  } = useSelector((state: RootStateOrAny) => state);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { companies } = companyState;
  const companiesMap: { [key: string]: Client } = {};
  const [shouldFetchClients, setShouldFetchClients] = useState<boolean>(true);

  useEffect(() => {
    if (shouldFetchClients && companies.length === 0) {
      dispatch(getCompanies(1, 100));
      setShouldFetchClients(true);
    }
  }, [dispatch, companies.length, shouldFetchClients]);

  companies.forEach((company: Client) => {
    companiesMap[company.id] = company;
  });

  const [quote, setQuote] = useState<Quote>(emptyQuote);
  const [showFormErrors, setShowFormErrors] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openConfirmModalItem, setOpenConfirmModalItem] = useState<boolean>(false);
  const [companyErr, setCompanyErr] = useState<boolean>(false);
  const [qiToDelete, setQiToDelete] = useState<QuoteItem | null>(null);
  const [qis, setQis] = useState<QuoteItem[]>([]);
  const [lastQuoteNum, setLastQuoteNum] = useState<number>(0);
  const [modalTask, setModalTask] = useState<Task | null>(null);
  const [openTaskModal, setOpenTaskModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [newQIid, setNewQIid] = useState<number>(0);
  const [openQiModal, setOpenQiModal] = useState<boolean>(false);
  const [modalQi, setModalQi] = useState<QuoteItem | null>(null);
  const [taskModalOpenedFromQi, setTaskModalOpenedFromQi] = useState<QuoteItem | null>(null);
  const [loadingQis, setLoadingQis] = useState<boolean>(false);

  useEffect(() => {
    if (openModal) {
      const getLastQuoteNum = async () => {
        const response = await axios.get(
          "/quotes?sort[0]=number:desc&pagination[limit]"
        );
        const lastQuoteNum = parseInt(
          response.data?.data[0]?.attributes?.number || 0
        );

        setLastQuoteNum(lastQuoteNum + 1);
        setQuote((quote) => ({
          ...quote,
          number: quote.number || lastQuoteNum + 1,
        }));
      };

      getLastQuoteNum();
    }
  }, [openModal]);

  useEffect(() => {
    if (extQuote) {
      setQuote({
        ...extQuote,
        date: new Date(extQuote.date),
        expiration_date: new Date(extQuote.expiration_date),
      });

      setQis([...(extQuote.quote_items || [])]);
    }
  }, [extQuote]);

  useEffect(() => {
    if (company) {
      setQuote((quote) => ({
        ...quote,
        company,
        currency: company.currency,
        base_rate: company.base_rate,
      }));
    }
  }, [company]);

  const fetchTranslate = React.useCallback(async () => {
    if (!task || quote.company.language === "en" || !quote.company.language) {
      setQis((qis) => {
        const newQis = qis.map((qi) => {
          if (qi.id === -1000 && task) {
            return {
              ...qi,
              title: task.title,
              description: task.description || "",
            };
          }
          return qi;
        });
        return newQis;
      });
      return null;
    }

    setLoadingQis(true);

    const titleRes = await axios.post("/cs/translate-text", {
      text: task.title,
      language: quote.company.language,
    });

    const descRes = await axios.post("/cs/translate-text", {
      text: task.description || "",
      language: quote.company.language,
    });

    setQis((qis) => {
      const newQis = qis.map((qi) => {
        if (qi.id === -1000) {
          return {
            ...qi,
            title: titleRes.data.text,
            description: descRes.data.text,
          };
        }
        return qi;
      });
      return newQis;
    });

    setLoadingQis(false);
  }, [quote.company.language, task]);

  useEffect(() => {
    if (!isEditingQuote && hasTask && quote.company?.id) {
      fetchTranslate();
    }
  }, [quote.company, isEditingQuote, hasTask, fetchTranslate]);

  useEffect(() => {
    if (hasTask && task) {
      let price = 0;

      if (task?.estimates?.length && company?.base_rate) {
        const estimate = task.estimates?.reduce((a, b) => {
          return a.hours > b.hours ? a : b;
        });

        price = estimate.hours * company?.base_rate;
      }

      const newQi: QuoteItem = {
        id: -1000,
        price,
        status: QuoteItemStatus.pending,
        task: task,
        title: task.title,
        description: task.description || "",
        order: 0,
      };

      setQis((qis) => [...qis, newQi]);
    }
  }, [hasTask, task, company]);

  const closeModal = () => {
    setShowFormErrors(false);
    setOpenModal(false);

    setTimeout(() => {
      setQuote(emptyQuote);
      setQis([]);
    }, 200);
  };

  const handleDelete = (quote: Quote) => {
    dispatch(deleteQuote(quote.id));
    setOpenConfirmModal(false);
    setOpenModal(false);
  };

  const handleOnChangeFormField = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | (Event & { target: { value: string; name: string } }),
    name = null
  ) => {
    if (name === null) {
      setQuote({
        ...quote,
        [event.target.name]: event.target.value,
      });
    } else {
      setQuote({
        ...quote,
        [name]: event.toString(),
      });
    }
  };

  const validateQuote = () => {
    if (!quote.number && !lastQuoteNum) {
      return false;
    } else if (!quote.company?.id) {
      setCompanyErr(true);
      return false;
    }
    return true;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!validateQuote()) {
      setShowFormErrors(true);
      return null;
    }

    setLoading(true);

    if (isEditingQuote) {
      await dispatch(
        updateQuote({
          data: {
            ...quote,
          },
        })
      );
    } else {
      const newQuote = {
        ...quote,
        number: quote.number || lastQuoteNum,
        quote_items: qis,
      };

      delete newQuote.id;

      await dispatch(
        createQuote({
          quote: newQuote,
        })
      );

      if (hasTask) dispatch(refreshTasks());
    }

    if (onSubmitCB) onSubmitCB();

    setLoading(false);
    closeModal();
  };

  const handleDeleteQuoteItem = (delQi: QuoteItem) => {
    const uptQis: QuoteItem[] = [...qis].filter((qi) => qi.id !== delQi.id);

    if (delQi.id > 0) {
      dispatch(deleteQuoteItem(delQi.id));
    }

    setQis(uptQis);
    setQuote({
      ...quote,
      quote_items: uptQis,
    });
    setOpenConfirmModalItem(false);
  };

  const updateQi = (
    uptQi: QuoteItem,
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | { target: { value: any; name: string } }
  ) => {
    const uptQis = [...qis].map((qi) => {
      if (qi.id === uptQi.id) {
        if (event.target.name === "task") {
          const task = event.target.value as Task;
          qi.task = task;
          qi.title = task.title;
          qi.description = task.description || "";
        } else {
          qi[event.target.name] = event.target.value;
        }
      }
      return qi;
    });

    setQis(uptQis);
  };

  const updateQiFromModal = (uptQi: QuoteItem) => {
    const uptQis = [...qis].map((qi) => {
      if (qi.id === uptQi.id) {
        qi.title = uptQi.title;
        qi.description = uptQi.description;
        qi.task = uptQi.task ? {
          ...uptQi.task,
        } : undefined;
        qi.subscription_item = uptQi.subscription_item;
      }
      return qi;
    });

    setQis(uptQis);
    setModalQi(null);
  };

  const updateQiStatus = (uptQi: QuoteItem, newStatus: QuoteItemStatus) => {
    const uptQis = [...qis].map((qi) => {
      if (qi.id === uptQi.id) {
        if (
          quote.delivery_time &&
          qi.task?.id &&
          newStatus === QuoteItemStatus.accepted &&
          qi.status !== QuoteItemStatus.accepted
        ) {
          qi.task.due_date = moment()
            .add(quote.delivery_time, "days")
            .format("YYYY-MM-DD");
          qi.task.hasChanges = true;
        }
        qi.status = newStatus;
      }
      return qi;
    });

    setQis(uptQis);
  };

  const handleCreateTaskCallback = (task: Task) => {
    if (taskModalOpenedFromQi) {
      const uptQis = [...qis].map((qi) => {
        if (qi.id === taskModalOpenedFromQi.id) {
          qi.task = task;
        }
        return qi;
      });

      dispatch(
        addTaskToCompany({
          companyId: quote.company.id,
          task: task,
        })
      );

      setQis(uptQis);
      setTaskModalOpenedFromQi(null);
      setModalQi({
        ...taskModalOpenedFromQi,
        task: task,
      });
    }
  };

  const handleUpdateTaskCallback = (task: Task) => {
    const uptQis = [...qis].map((qi) => {
      if (qi.id === taskModalOpenedFromQi?.id) {
        qi.task = task;
      }
      return qi;
    });

    setQis(uptQis);
  };

  const handleDeleteTaskCallback = (task: Task) => {
    const uptQis = [...qis].map((qi) => {
      if (qi.id === taskModalOpenedFromQi?.id) {
        qi.task = undefined;
      }
      return qi;
    });

    setQis(uptQis);
  };

  return (
    <div style={{ width: 1000 }}>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        open={openModal}
        onClose={closeModal}
      >
        <DialogTitle>
          {isEditingQuote ? "Edit Quote" : "New Quote"}
        </DialogTitle>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 56.5,
              width: "100%",
              minWidth: 600,
              paddingBottom: 45,
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <QuoteForm
                quote={quote}
                lastQuoteNum={lastQuoteNum}
                showFormErrors={showFormErrors}
                companyErr={companyErr}
                companies={companies}
                companiesMap={companiesMap}
                setCompanyErr={setCompanyErr}
                setQuote={setQuote}
                setQis={setQis}
                handleOnChangeFormField={handleOnChangeFormField}
              />
              <QuoteItems
                quote={quote}
                qis={qis}
                loadingQis={loadingQis}
                newQIid={newQIid}
                setNewQIid={setNewQIid}
                setQis={setQis}
                setQuote={setQuote}
                openConfirmItemModal={(qi) => {
                  setOpenConfirmModalItem(true);
                  setQiToDelete(qi);
                }}
                handleOpenTaskModal={(task) => {
                  setModalTask(task);
                  setOpenTaskModal(true);
                }}
                setModalQi={setModalQi}
                setOpenQiModal={setOpenQiModal}
                updateQi={updateQi}
                updateQiStatus={updateQiStatus}
                user={user}
              />
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 24px 20px",
              }}
            >
              <div>
                {isEditingQuote && (
                  <Button
                    onClick={() => setOpenConfirmModal(true)}
                    color="error"
                  >
                    Delete Quote
                  </Button>
                )}
              </div>
              <div>
                <Button onClick={closeModal}>Cancel</Button>
                <Button type="submit">
                  {isEditingQuote ? "Update" : "Create"}
                </Button>
              </div>
            </DialogActions>
          </Box>
        )}
      </Dialog>

      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          if (isEditingQuote) {
            handleDelete(quote);
          }
        }}
        text="Do you really want to delete the quote?"
      />

      <ConfirmDialog
        openModal={openConfirmModalItem}
        setOpenModal={setOpenConfirmModalItem}
        confirmHandler={() => {
          if (qiToDelete) {
            handleDeleteQuoteItem(qiToDelete);
          }
        }}
        text="Do you really want to delete the quote item?"
      />

      <CreateTask
        openModal={openTaskModal}
        setOpenModal={setOpenTaskModal}
        taskFromQi={modalTask}
        task={modalTask}
        projects={projectOptions}
        updateCallback={handleUpdateTaskCallback}
        deleteCallback={handleDeleteTaskCallback}
        createCallback={handleCreateTaskCallback}
      />

      <QuoteItemModal
        openModal={openQiModal}
        quote={quote}
        setOpenModal={() => {
          setOpenQiModal(false);
          setModalQi(null);
        }}
        qi={modalQi}
        updateQi={updateQiFromModal}
        company={quote?.company}
        setModalTask={(qi) => {
          setTaskModalOpenedFromQi(qi);

          let task = {
            title: qi.title,
            description: qi.description,
            company: quote.company,
            status: "Gathering Data",
          } as Task;

          if (qi.id > 0) {
            task.quote_items = [qi.id];
          }

          if (qi.task?.id) {
            task = {
              title: "",
              description: "",
              status: "Gathering Data",
              ...qi.task,
              company: quote.company,
            } as Task;
          }

          setModalTask(task);
          setOpenTaskModal(true);
        }}
      />
    </div>
  );
};

export default QuoteModal;
