import React from 'react';
import { Box, TextField, IconButton, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useIntl } from 'react-intl';

interface ChatInputProps {
  message: string;
  isTyping: boolean;
  isCreatingChat: boolean;
  onMessageChange: (message: string) => void;
  onSendMessage: () => void;
  onKeyPress: (e: React.KeyboardEvent) => void;
}

export const ChatInput: React.FC<ChatInputProps> = ({
  message,
  isTyping,
  isCreatingChat,
  onMessageChange,
  onSendMessage,
  onKeyPress
}) => {
  const intl = useIntl();

  return (
    <Box sx={{ 
      p: 2,
      borderTop: 1,
      borderColor: 'divider',
      bgcolor: 'background.paper',
      display: 'flex',
      gap: 1,
      alignItems: 'flex-start'
    }}>
      <TextField
        fullWidth
        multiline
        maxRows={4}
        value={message}
        onChange={(e) => onMessageChange(e.target.value)}
        onKeyPress={onKeyPress}
        placeholder={intl.formatMessage({ id: 'general.Type-your-message' })}
        disabled={isTyping || isCreatingChat}
      />
      <IconButton
        color="primary"
        onClick={onSendMessage}
        disabled={!message.trim() || isTyping || isCreatingChat}
        sx={{ 
          borderRadius: '50%',
          width: 40,
          height: 40,
          mt: 1
        }}
      >
        {isTyping ? <CircularProgress size={24} /> : <SendIcon />}
      </IconButton>
    </Box>
  );
};
