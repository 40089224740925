import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Typography,
  IconButton,
  CircularProgress,
  Grid,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Quote, QuoteItem, Task, QuoteItemStatus } from "../types";
import { getItemStyle, getListStyle } from "../utils";
import QuoteItemRow from "../../QuoteItemRow";

interface QuoteItemsProps {
  quote: Quote;
  qis: QuoteItem[];
  loadingQis: boolean;
  newQIid: number;
  setNewQIid: (id: number) => void;
  setQis: (qis: QuoteItem[]) => void;
  setQuote: (quote: Quote) => void;
  openConfirmItemModal: (qi: QuoteItem) => void;
  handleOpenTaskModal: (task: Task | null) => void;
  setModalQi: (qi: QuoteItem) => void;
  setOpenQiModal: (open: boolean) => void;
  updateQi: (
    qi: QuoteItem,
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | { target: { value: any; name: string } }
  ) => void;
  updateQiStatus: (qi: QuoteItem, status: QuoteItemStatus) => void;
  user: { id: number };
}

const QuoteItems: React.FC<QuoteItemsProps> = ({
  quote,
  qis,
  loadingQis,
  newQIid,
  setNewQIid,
  setQis,
  setQuote,
  openConfirmItemModal,
  handleOpenTaskModal,
  setModalQi,
  setOpenQiModal,
  updateQi,
  updateQiStatus,
  user,
}) => {
  const intl = useIntl();

  const onDragEnd = (event: any) => {
    if (!event.destination) {
      return null;
    }

    const items = Array.from(qis);
    const [removed] = items.splice(event.source.index, 1);
    items.splice(event.destination.index, 0, removed);

    const updatedItems = items.map((qi, index) => ({
      ...qi,
      order: index + 1,
    }));

    setQis(updatedItems);
  };

  const getTotalSum = () => {
    const total = qis?.reduce((n, { price: p }) => n + parseFloat(`${p}`), 0) || 0;
    return total % 1 !== 0 ? total.toFixed(2) : total;
  };

  const getTotalHours = () => {
    const allEstimateHours = qis?.map((qi) => {
      if (qi.task?.estimates?.length) {
        return qi.task?.estimates[0].hours;
      }
      return 0;
    });

    let totalHours = 0;

    if (allEstimateHours.length) {
      totalHours = allEstimateHours.reduce((a, b) => a + b);

      if (!totalHours) {
        return "";
      }

      if (totalHours % 1 !== 0) {
        totalHours = parseFloat(totalHours.toFixed(1));
      }
    }

    const h = intl.formatMessage({
      id: "general.hours-shortened",
      defaultMessage: "h",
    });

    return `(${totalHours}${h})`;
  };

  if (loadingQis) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <Typography variant="h5">
          <FormattedMessage
            id="quotes-page.Quote-items"
            defaultMessage="Quote items"
          />
        </Typography>
        <IconButton
          size="large"
          title={intl.formatMessage({
            id: "quote-modal.Add-quote-item",
            defaultMessage: "Add quote item",
          })}
          onClick={() => {
            const newQi: QuoteItem = {
              id: newQIid - 1,
              title: intl.formatMessage({
                id: "quote-modal.New-quote-item",
                defaultMessage: "New quote item",
              }),
              status: QuoteItemStatus.pending,
              price: 0,
              description: "",
              order: 0,
            };

            setNewQIid(newQIid - 1);

            const newQis = [newQi, ...qis].map((qi, i) => ({
              ...qi,
              order: i,
            }));

            setQis(newQis);
            setQuote({
              ...quote,
              quote_items: newQis,
            });
          }}
        >
          <AddCircle />
        </IconButton>
      </div>
      <div style={{ paddingLeft: 5 }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {Boolean(qis?.length) &&
                  qis.map((qi, index) => (
                    <Draggable
                      key={qi.id}
                      draggableId={String(qi.id)}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <QuoteItemRow
                          forwardRef={provided.innerRef}
                          {...provided.draggableProps}
                          dragHandleProps={provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          isDragging={snapshot.isDragging}
                          key={qi.id}
                          company={quote.company}
                          qi={qi}
                          openConfirmItemModal={openConfirmItemModal}
                          openTaskModal={(task) => {
                            if (task?.seen_by && !task.seen_by.length) {
                              setQis(
                                qis.map((i) => {
                                  if (i.id === qi.id && i.task) {
                                    i.task.seen_by = [{ id: user.id }];
                                  }
                                  return i;
                                })
                              );
                            }
                            handleOpenTaskModal(task);
                          }}
                          openQiModal={() => {
                            setModalQi(qi);
                            setOpenQiModal(true);
                          }}
                          updateItem={updateQi}
                          updateQiStatus={updateQiStatus}
                          deleteDisabled={false}
                          updateQiTask={(task) => {
                            setQis(
                              qis.map((i) => {
                                if (i.id === qi.id && task) {
                                  i.task = task;
                                  i.title = task.title || "";
                                  i.description = task.description || "";
                                }
                                return i;
                              })
                            );
                          }}
                        />
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Grid container>
        <Grid item sm={12} textAlign="right" mt={2}>
          <span>{quote.currency} </span>
          <span>{getTotalSum()} </span>
          <span>{getTotalHours()}</span>
        </Grid>
      </Grid>
    </>
  );
};

export default QuoteItems;
