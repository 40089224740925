import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles/quotePdfStyles';
import { Quote } from '../../../../components/task/types';
import { useIntl } from 'react-intl';
import { getCurrencyText } from '../../utils/helpers';

interface PriceSectionProps {
  quote: Quote | null;
}

// Custom number formatting function that uses spaces as thousand separators
const formatNumber = (num: number): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const PriceSection: React.FC<PriceSectionProps> = ({ quote }) => {
  const intl = useIntl();
  const quoteItems = quote?.quote_items || [];

  return (
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>
        {intl.formatMessage({ id: 'quote.pdf.price' })}
      </Text>
      
      {/* Main price rows */}
      <View style={styles.priceRows}>
        {quoteItems.slice(0, -1).map((qi, index) => (
          <View key={index} style={styles.priceRow}>
            <Text style={styles.priceTitle}>
              {qi.title}
              {quote?.company?.hourly_rate > 0 && ` [${(qi.price / quote.company.hourly_rate).toFixed(1)}h]`}
            </Text>
            <Text style={styles.priceText}>{formatNumber(qi.price)}</Text>
          </View>
        ))}
      </View>

      {/* Last row and total in a fixed group */}
      <View wrap={false}>
        {quoteItems.slice(-1).map((qi, index) => (
          <View key={index} style={styles.priceRow}>
            <Text style={styles.priceTitle}>
              {qi.title}
              {quote?.company?.hourly_rate > 0 && ` [${(qi.price / quote.company.hourly_rate).toFixed(1)}h]`}
            </Text>
            <Text style={styles.priceText}>{formatNumber(qi.price)}</Text>
          </View>
        ))}
        <View style={styles.totalRow}>
          <Text style={styles.totalText}>
            {intl.formatMessage({ id: 'quote.pdf.total' })}
          </Text>
          <Text style={styles.totalText}>
            {formatNumber(quoteItems.reduce((acc, qi) => acc + qi.price, 0) || 0)}
          </Text>
        </View>
      </View>

      <Text style={styles.smallText}>
        {intl.formatMessage(
          { id: 'quote.pdf.priceDisclaimer' },
          { currency: getCurrencyText(quote?.currency, intl) }
        )}
      </Text>
    </View>
  );
};
