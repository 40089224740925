import React, { useEffect } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useConversations } from '../hooks/useConversations';
import { useChat } from '../hooks/useChat';
import { ConversationList } from '../components/conversation/ConversationList';
import { ChatMessages } from '../components/chat/ChatMessages';
import { ChatInput } from '../components/chat/ChatInput';

const AI = () => {
  const intl = useIntl();
  const {
    conversations,
    selectedConversation,
    isCreatingChat,
    setSelectedConversation,
    fetchConversations,
    startNewChat,
    handleEditConversationTitle,
    handleDeleteConversation
  } = useConversations();

  const {
    message,
    isTyping,
    messagesEndRef,
    setMessage,
    handleSendMessage,
    handleKeyPress
  } = useChat({
    selectedConversation,
    setSelectedConversation,
    fetchConversations
  });

  // Load conversations when component mounts
  useEffect(() => {
    fetchConversations();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ flexGrow: 1, height: 'calc(100vh - 64px)', p: 2 }}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        {/* Conversations List */}
        <Grid item xs={3} sx={{ height: '100%' }}>
          <ConversationList
            conversations={conversations}
            selectedConversation={selectedConversation}
            isCreatingChat={isCreatingChat}
            onSelectConversation={setSelectedConversation}
            onStartNewChat={startNewChat}
            onEditTitle={handleEditConversationTitle}
            onDeleteConversation={handleDeleteConversation}
          />
        </Grid>

        {/* Chat Area */}
        <Grid item xs={9} sx={{ height: '100%' }}>
          <Paper sx={{ 
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}>
            {selectedConversation || isCreatingChat ? (
              <>
                <ChatMessages
                  messages={selectedConversation?.messages || []}
                  isTyping={isTyping}
                  isCreatingChat={isCreatingChat}
                  messagesEndRef={messagesEndRef}
                />
                <ChatInput
                  message={message}
                  isTyping={isTyping}
                  isCreatingChat={isCreatingChat}
                  onMessageChange={setMessage}
                  onSendMessage={handleSendMessage}
                  onKeyPress={handleKeyPress}
                />
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Typography variant="body1" color="textSecondary">
                  {intl.formatMessage({ id: 'general.Select-or-start-chat' })}
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AI;
