import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Release } from './types';
import { FormattedMessage } from "react-intl";

interface TrelloCardsModalProps {
  open: boolean;
  onClose: () => void;
  release: Release;
}

const TrelloCardsModal: React.FC<TrelloCardsModalProps> = ({ open, onClose, release }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <FormattedMessage 
            id="release.trello-cards.title" 
            defaultMessage="Trello Cards"
          />
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <List dense>
          {release.tasks?.map((task) => (
            <ListItem 
              key={task.id}
              sx={{ 
                padding: '2px 0',
                '& .MuiListItemText-root': {
                  margin: 0
                }
              }}
            >
              <ListItemText>
                <a 
                  href={task.trello_url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{ color: '#0079bf', textDecoration: 'none' }}
                >
                  {task.title}
                </a>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default TrelloCardsModal;
