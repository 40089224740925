import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { handleUpworkCallback } from "../../../redux/reducerSlices/upwork";

const UpworkCallback = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const code = new URLSearchParams(location.search).get("code");

  useEffect(() => {
    const handleCallback = async () => {
      if (code) {
        await dispatch(handleUpworkCallback(code) as any);
      }
      history.push("/profile/integrations");
    };

    handleCallback();
  }, [code, dispatch, history]);

  return (
    <div>
      Connecting to Upwork...
    </div>
  );
};

export default UpworkCallback;
