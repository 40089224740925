import React from 'react';
import { Box } from '@mui/material';
import { Message } from '../../types/conversation';
import { ChatMessage } from './ChatMessage';
import { TypingIndicator } from './TypingIndicator';

interface ChatMessagesProps {
  messages: Message[];
  isTyping: boolean;
  isCreatingChat: boolean;
  messagesEndRef: React.RefObject<HTMLDivElement>;
}

export const ChatMessages: React.FC<ChatMessagesProps> = ({
  messages,
  isTyping,
  isCreatingChat,
  messagesEndRef
}) => (
  <Box sx={{ 
    flexGrow: 1, 
    overflow: 'auto',
    p: 2,
    display: 'flex',
    flexDirection: 'column'
  }}>
    {messages?.map((message, index) => (
      <ChatMessage key={index} message={message} />
    ))}
    {(isTyping || isCreatingChat) && (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          mb: 2,
        }}
      >
        <Box
          sx={{
            bgcolor: 'grey.100',
            borderRadius: '20px',
            borderBottomLeftRadius: '4px',
          }}
        >
          <TypingIndicator />
        </Box>
      </Box>
    )}
    <div ref={messagesEndRef} />
  </Box>
);
