import { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Alert,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";
import toast from "react-hot-toast";

interface EmailInfo {
  EmailAddressBCC: string;
  EmailAddressCC: string;
  EmailAddressFrom: string;
  EmailAddressTo: string;
  EmailBody: string;
  EmailSubject: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  invoiceId?: string;
}

const EmailModal: React.FC<Props> = ({ open, onClose, invoiceId }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [emailInfo, setEmailInfo] = useState<EmailInfo>({
    EmailAddressBCC: "",
    EmailAddressCC: "",
    EmailAddressFrom: "",
    EmailAddressTo: "",
    EmailBody: "",
    EmailSubject: ""
  });

  const getErrorMessage = (error: any): string => {
    if (typeof error === 'string') return error;
    if (error.response?.data?.error) {
      const errorData = error.response.data.error;
      if (typeof errorData === 'string') return errorData;
      if (errorData.message) return errorData.message;
      if (errorData.ErrorInformation?.message) return errorData.ErrorInformation.message;
      return JSON.stringify(errorData);
    }
    if (error.message) return error.message;
    return 'An unknown error occurred';
  };

  const fetchEmailInfo = useCallback(async () => {
    setError(null);
    try {
      const response = await axios.get(`/fortnox/invoice-email-info/${invoiceId}`);
      setEmailInfo(response.data.data);
    } catch (err: any) {
      console.error('Failed to fetch email info:', err);
      const message = getErrorMessage(err);
      setError(message);
    }
  }, [invoiceId]);

  useEffect(() => {
    if (open && invoiceId) {
      fetchEmailInfo();
    }
    // Cleanup on close
    return () => {
      setError(null);
      setLoading(false);
    };
  }, [open, invoiceId, fetchEmailInfo]);

  const handleChange = (field: keyof EmailInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInfo(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleSend = async () => {
    setLoading(true);
    setError(null);
    try {
      await axios.post(`/fortnox/send-invoice/${invoiceId}`, {
        emailInfo
      });
      toast.success(intl.formatMessage({
        id: "invoice.sent-success",
        defaultMessage: "Invoice sent successfully"
      }));
      // Only close if successful
      onClose();
    } catch (err: any) {
      console.error('Failed to send invoice:', err);
      const message = getErrorMessage(err);
      setError(message);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setError(null);
    setLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <FormattedMessage
          id="invoice.send-email"
          defaultMessage="Send Invoice by Email"
        />
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={intl.formatMessage({
                id: "invoice.email-to",
                defaultMessage: "To"
              })}
              value={emailInfo.EmailAddressTo}
              onChange={handleChange('EmailAddressTo')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={intl.formatMessage({
                id: "invoice.email-from",
                defaultMessage: "From"
              })}
              value={emailInfo.EmailAddressFrom}
              onChange={handleChange('EmailAddressFrom')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={intl.formatMessage({
                id: "invoice.email-cc",
                defaultMessage: "CC"
              })}
              value={emailInfo.EmailAddressCC}
              onChange={handleChange('EmailAddressCC')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={intl.formatMessage({
                id: "invoice.email-bcc",
                defaultMessage: "BCC"
              })}
              value={emailInfo.EmailAddressBCC}
              onChange={handleChange('EmailAddressBCC')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={intl.formatMessage({
                id: "invoice.email-subject",
                defaultMessage: "Subject"
              })}
              value={emailInfo.EmailSubject}
              onChange={handleChange('EmailSubject')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label={intl.formatMessage({
                id: "invoice.email-body",
                defaultMessage: "Message"
              })}
              value={emailInfo.EmailBody}
              onChange={handleChange('EmailBody')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          onClick={handleSend}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          <FormattedMessage id="invoice.Send" defaultMessage="Send" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailModal;
