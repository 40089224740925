import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";

import ConfirmDialog from "../ConfirmDialog";
import { Organization } from "./types";
import {
  createOrganization,
  updateOrganization,
  deleteOrganization,
} from "../../redux/reducerSlices/owned-organization";
import { connectTrello } from "../../redux/reducerSlices/trello";
import { refreshMe } from "../../redux/reducerSlices/me";
import { getOrganizations } from "../../redux/reducerSlices/organization";

const emptyOrganization = {
  id: null,
  name: "",
  trello_token: "",
  upwork_enabled: false,
  upwork_access_token: "",
  upwork_access_secret: "",
  fortnox_enabled: false,
  fortnox_access_token: "",
  cad_billing_details: "",
  usd_billing_details: "",
} as Organization;

const OrganizationModal: React.FC<{
  extOrganization?: Organization | null;
  setOpenModal: any;
  openModal: boolean;
  onCreateCB?: () => void;
}> = ({ extOrganization, setOpenModal, openModal, onCreateCB }) => {
  const isEdit = Boolean(extOrganization?.id);
  const dispatch = useDispatch();
  const intl = useIntl();
  const { user } = useSelector((state: any) => state);

  const [organization, setOrganization] =
    useState<Organization>(emptyOrganization);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!openModal) return null;

    if (extOrganization) {
      setOrganization({
        ...emptyOrganization,
        ...extOrganization,
      });
    } else {
      setOrganization(emptyOrganization);
    }
  }, [extOrganization, setOrganization, openModal]);

  const closeModal = () => {
    setOpenModal(false);
    setTimeout(() => {
      setOrganization(emptyOrganization);
    }, 200);
  };

  const handleDelete = (organization: Organization) => {
    dispatch(deleteOrganization(organization.id));
    setOpenConfirmModal(false);
    setOpenModal(false);
    setTimeout(() => {
      setOrganization(emptyOrganization);
    }, 200);
  };

  const handleOnChangeFormField = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | (Event & { target: { value: string; name: string } }),
    name = null
  ) => {
    if (name === null) {
      setOrganization({
        ...organization,
        [event.target.name]: event.target.value,
      });
    } else {
      setOrganization({
        ...organization,
        [name]: event.toString(),
      });
    }
  };

  const validateUser = () => {
    return true;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!validateUser()) {
      return null;
    }

    setLoading(true);

    // Clean up organization data before sending
    const orgData = {
      ...organization,
      // Only include Upwork fields if enabled
      ...(organization.upwork_enabled ? {
        upwork_enabled: true,
        upwork_access_token: user.upwork_token,
        upwork_access_secret: user.upwork_refresh_token
      } : {
        upwork_enabled: false,
        upwork_access_token: "",
        upwork_access_secret: ""
      }),
      // Only include Fortnox fields if enabled
      ...(organization.fortnox_enabled ? {
        fortnox_enabled: true,
        fortnox_access_token: user.fortnox_access_token
      } : {
        fortnox_enabled: false,
        fortnox_access_token: ""
      })
    };

    if (isEdit) {
      await dispatch(
        updateOrganization(orgData)
      );

      // Refresh organizations list to update UI
      await dispatch(getOrganizations());

      if (organization.id === user.selected_organization_id) {
        await dispatch(refreshMe());
      }
    } else {
      const newOrg = {
        ...orgData,
      };

      delete newOrg.id;

      await dispatch(createOrganization(newOrg));

      if (onCreateCB) {
        onCreateCB();
      }
    }

    setLoading(false);
    closeModal();
  };

  const handleOrgTrelloChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setOrganization({
        ...organization,
        trello_token: user.trello_token,
      });
    } else {
      setOrganization({
        ...organization,
        trello_token: "",
      });
    }
  };

  const handleOrgUpworkChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrganization({
      ...organization,
      upwork_enabled: event.target.checked,
      upwork_access_token: event.target.checked ? user.upwork_token : "",
      upwork_access_secret: event.target.checked ? user.upwork_refresh_token : ""
    });
  };

  const handleOrgFortnoxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrganization({
      ...organization,
      fortnox_enabled: event.target.checked,
      fortnox_access_token: event.target.checked ? user.fortnox_access_token : ""
    });
  };

  const getModalTitle = () => {
    return isEdit ? (
      <FormattedMessage
        id="organization-modal.Edit-Organization"
        defaultMessage="Edit Organization"
      />
    ) : (
      <FormattedMessage
        id="general.New-Organization"
        defaultMessage="New Organization"
      />
    );
  };

  const renderTrelloConnection = () => {
    if (!Boolean(user.trello_token)) {
      return (
        <Button
          onClick={() => {
            dispatch(connectTrello());
          }}
        >
          <FormattedMessage
            id="general.Connect-Trello"
            defaultMessage="Connect Trello"
          />
        </Button>
      );
    }

    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              onChange={handleOrgTrelloChange}
              checked={Boolean(organization.trello_token)}
            />
          }
          label={intl.formatMessage({
            id: "general.Connect-organization-Trello",
            defaultMessage: "Connect Trello",
          })}
        />
      </FormGroup>
    );
  };

  const renderUpworkConnection = () => {
    // Only show Upwork switch if user has access and token
    if (!user.upwork_access || !user.upwork_token) {
      return null;
    }

    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              onChange={handleOrgUpworkChange}
              checked={Boolean(organization.upwork_enabled)}
            />
          }
          label={intl.formatMessage({
            id: "general.Connect-organization-Upwork",
            defaultMessage: "Connect organization to Upwork",
          })}
        />
      </FormGroup>
    );
  };

  const renderFortnoxConnection = () => {
    // Only show Fortnox switch if user has access and token
    if (!user.fortnox_access || !user.fortnox_access_token) {
      return null;
    }

    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              onChange={handleOrgFortnoxChange}
              checked={Boolean(organization.fortnox_enabled)}
            />
          }
          label={intl.formatMessage({
            id: "general.Connect-organization-Fortnox",
            defaultMessage: "Connect organization to Fortnox",
          })}
        />
      </FormGroup>
    );
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          closeModal();
        }}
      >
        <DialogTitle>{getModalTitle()}</DialogTitle>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 56.5,
              width: 600,
              maxWidth: "100%",
              paddingBottom: 45,
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            style={{
              width: 600,
              maxWidth: "100%",
            }}
          >
            <DialogContent>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-end"
                spacing={1}
              >
                <Grid item sm={12}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    value={organization.name || ""}
                    label={intl.formatMessage({
                      id: "general.Name",
                      defaultMessage: "Name",
                    })}
                    type="text"
                    variant="outlined"
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>

                {/* Billing Details */}
                <Grid item sm={12}>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6">
                    <FormattedMessage
                      id="general.Billing-Details"
                      defaultMessage="Billing Details"
                    />
                  </Typography>
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    id="cad_billing_details"
                    name="cad_billing_details"
                    value={organization.cad_billing_details || ""}
                    label={intl.formatMessage({
                      id: "general.CAD-Billing-Details",
                      defaultMessage: "CAD Billing Details",
                    })}
                    variant="outlined"
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    id="usd_billing_details"
                    name="usd_billing_details"
                    value={organization.usd_billing_details || ""}
                    label={intl.formatMessage({
                      id: "general.USD-Billing-Details",
                      defaultMessage: "USD Billing Details",
                    })}
                    variant="outlined"
                    onChange={(event) => handleOnChangeFormField(event)}
                  />
                </Grid>

                {/* Integrations */}
                <Grid item sm={12}>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6">
                    <FormattedMessage
                      id="general.Integrations"
                      defaultMessage="Integrations"
                    />
                  </Typography>
                </Grid>

                <Grid item sm={12}>
                  {renderTrelloConnection()}
                </Grid>

                {/* Only show Upwork section if user has access and token */}
                {user.upwork_access && user.upwork_token && (
                  <>
                    <Grid item sm={12}>
                      <Divider sx={{ my: 1 }} />
                    </Grid>
                    <Grid item sm={12}>
                      {renderUpworkConnection()}
                    </Grid>
                  </>
                )}

                {/* Only show Fortnox section if user has access and token */}
                {user.fortnox_access && user.fortnox_access_token && (
                  <>
                    <Grid item sm={12}>
                      <Divider sx={{ my: 1 }} />
                    </Grid>
                    <Grid item sm={12}>
                      {renderFortnoxConnection()}
                    </Grid>
                  </>
                )}

              </Grid>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 24px 20px",
              }}
            >
              <div>
                {isEdit && (
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenConfirmModal(true);
                    }}
                    color="error"
                  >
                    <FormattedMessage
                      id="general.Delete-Organization"
                      defaultMessage="Delete Organization"
                    />
                  </Button>
                )}
              </div>
              <div>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    closeModal();
                  }}
                >
                  <FormattedMessage
                    id="general.Cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button type="submit">
                  {isEdit ? (
                    <FormattedMessage
                      id="general.Update"
                      defaultMessage="Update"
                    />
                  ) : (
                    <FormattedMessage
                      id="general.Create"
                      defaultMessage="Create"
                    />
                  )}
                </Button>
              </div>
            </DialogActions>
          </Box>
        )}
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          if (isEdit) {
            handleDelete(extOrganization);
          }
        }}
        text={intl.formatMessage({
          id: "ogranization-modal.Do-you-really-want-to-delete-ogranization",
          defaultMessage: "Do you really want to delete the ogranization?",
        })}
      />
    </div>
  );
};

export default OrganizationModal;
