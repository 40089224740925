import { Text, View, StyleSheet, Font, Link, Image } from '@react-pdf/renderer';
import { ReactNode } from 'react';
import MulishRegular from '../../assets/fonts/Mulish-Regular.ttf';
import MulishBold from '../../assets/fonts/Mulish-Bold.ttf';
import MulishItalic from '../../assets/fonts/Mulish-Italic.ttf';
import { getProxiedImageUrl } from '../../utils/imageProxy';

// Register fonts
Font.register({
  family: 'Mulish',
  fonts: [
    { src: MulishRegular },
    { src: MulishBold, fontWeight: 'bold' },
    { src: MulishItalic, fontStyle: 'italic' }
  ]
});

Font.register({
  family: 'Courier',
  src: 'Courier'
});

const styles = StyleSheet.create({
  paragraph: {
    marginBottom: 12,
    fontSize: 14,
    fontFamily: 'Mulish'
  },
  heading1: {
    fontSize: 20,
    fontFamily: 'Mulish',
    marginBottom: 10
  },
  heading2: {
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Mulish',
    marginBottom: 14
  },
  heading3: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Mulish',
    marginBottom: 12
  },
  listItem: {
    flexDirection: 'row',
    marginBottom: 6,
  },
  listMarker: {
    width: 24,
    fontSize: 14,
    fontFamily: 'Mulish'
  },
  listItemContent: {
    flex: 1,
    fontSize: 14,
    fontFamily: 'Mulish',
    paddingRight: 10
  },
  nestedContent: {
    marginLeft: 24
  },
  bold: {
    fontWeight: 'bold',
    fontFamily: 'Mulish'
  },
  italic: {
    fontStyle: 'italic',
    fontFamily: 'Mulish'
  },
  link: {
    color: '#0000EE',
    textDecoration: 'underline',
    fontFamily: 'Mulish'
  },
  codeBlock: {
    backgroundColor: '#f5f5f5',
    padding: 8,
    marginVertical: 8,
    borderRadius: 4,
    fontFamily: 'Courier',
    fontSize: 14,
  },
  inlineCode: {
    backgroundColor: '#f5f5f5',
    padding: '0 4',
    fontFamily: 'Courier',
    fontSize: 14,
  },
  image: {
    marginVertical: 8,
    maxWidth: '100%',
    maxHeight: '400',
    objectFit: 'contain',
  },
  imageError: {
    marginVertical: 8,
    color: '#ff0000',
    fontSize: 10,
    fontStyle: 'italic',
    fontFamily: 'Mulish'
  }
});

// Helper function to validate and format URLs
const formatUrl = (url: string): string => {
  try {
    // Remove any title attribute that might be present
    url = url.split(' ')[0];
    
    // If it's a mailto link, return as is
    if (url.startsWith('mailto:')) {
      return url;
    }

    // If it's a relative path starting with /, prepend origin
    if (url.startsWith('/')) {
      url = `${window.location.origin}${url}`;
    }
    
    // If URL doesn't start with http(s), prepend https://
    if (!url.match(/^https?:\/\//i)) {
      url = `https://${url}`;
    }

    // Use our proxy for all images
    return getProxiedImageUrl(url);
  } catch (error) {
    return url;
  }
};

// Parse image dimensions from markdown
const parseImageDimensions = (url: string): { width?: number; height?: number } => {
  const dimensions: { width?: number; height?: number } = {};
  
  // Check for width and height in URL parameters
  try {
    const urlObj = new URL(url);
    const width = urlObj.searchParams.get('width');
    const height = urlObj.searchParams.get('height');
    
    if (width) dimensions.width = parseInt(width, 10);
    if (height) dimensions.height = parseInt(height, 10);
  } catch (e) {
    // If URL parsing fails, check for inline dimensions
    const dimensionMatch = url.match(/=(\d+)x(\d+)/);
    if (dimensionMatch) {
      dimensions.width = parseInt(dimensionMatch[1], 10);
      dimensions.height = parseInt(dimensionMatch[2], 10);
    }
  }
  
  return dimensions;
};

// Helper function to process inline markdown
const processInlineMarkdown = (text: string): ReactNode[] => {
  const parts: ReactNode[] = [];
  let currentText = '';
  let i = 0;

  const collectUrlWithParentheses = (): string => {
    let url = '';
    let parenCount = 1; // Start with 1 for the opening parenthesis
    i++; // Skip the opening parenthesis

    while (i < text.length && parenCount > 0) {
      if (text[i] === '(') {
        parenCount++;
      } else if (text[i] === ')') {
        parenCount--;
      }
      
      if (parenCount > 0) {
        url += text[i];
      }
      i++;
    }

    return url.trim();
  };

  while (i < text.length) {
    // Handle images
    if (text.slice(i, i + 2) === '![') {
      if (currentText) {
        parts.push(<Text key={parts.length}>{currentText}</Text>);
        currentText = '';
      }

      i += 2; // Skip ![
      let altText = '';
      while (i < text.length && text[i] !== ']') {
        altText += text[i];
        i++;
      }
      i++; // Skip ]

      if (i < text.length && text[i] === '(') {
        const url = collectUrlWithParentheses();

        try {
          const formattedUrl = formatUrl(url);
          const dimensions = parseImageDimensions(url);
          const imageStyle = {
            ...styles.image,
            ...(dimensions.width && { width: dimensions.width }),
            ...(dimensions.height && { height: dimensions.height }),
          };

          parts.push(
            <Image key={parts.length} src={formattedUrl} style={imageStyle} />
          );
        } catch (error) {
          parts.push(
            <Text key={parts.length} style={styles.imageError}>
              Failed to load image: {altText}
            </Text>
          );
        }
        continue;
      }
    }

    // Handle links
    if (text[i] === '[') {
      if (currentText) {
        parts.push(<Text key={parts.length}>{currentText}</Text>);
        currentText = '';
      }

      i++; // Skip [
      let linkText = '';
      while (i < text.length && text[i] !== ']') {
        linkText += text[i];
        i++;
      }
      i++; // Skip ]

      if (i < text.length && text[i] === '(') {
        const url = collectUrlWithParentheses();

        try {
          const formattedUrl = formatUrl(url);
          parts.push(
            <Link
              key={parts.length}
              style={styles.link}
              src={formattedUrl}
            >
              {linkText}
            </Link>
          );
        } catch (error) {
          // Fallback to plain text if URL is invalid
          parts.push(<Text key={parts.length}>{linkText}</Text>);
        }
        continue;
      }
    }

    // Handle inline code
    if (text.slice(i, i + 1) === '`') {
      if (currentText) {
        parts.push(<Text key={parts.length}>{currentText}</Text>);
        currentText = '';
      }

      i++;
      let codeContent = '';
      while (i < text.length && text[i] !== '`') {
        codeContent += text[i];
        i++;
      }
      i++; // Skip closing backtick

      parts.push(
        <Text key={parts.length} style={styles.inlineCode}>
          {codeContent}
        </Text>
      );
      continue;
    }

    // Handle bold/italic
    if (text[i] === '*' || text[i] === '_') {
      if (currentText) {
        parts.push(<Text key={parts.length}>{currentText}</Text>);
        currentText = '';
      }

      const marker = text[i];
      const isDouble = text[i + 1] === marker;
      const style = isDouble ? styles.bold : styles.italic;
      const length = isDouble ? 2 : 1;
      
      i += length;
      let content = '';
      
      while (i < text.length) {
        if (text.slice(i, i + length) === marker.repeat(length)) {
          i += length;
          break;
        }
        content += text[i];
        i++;
      }

      parts.push(
        <Text key={parts.length} style={style}>
          {content}
        </Text>
      );
      continue;
    }

    currentText += text[i];
    i++;
  }

  if (currentText) {
    parts.push(<Text key={parts.length}>{currentText}</Text>);
  }

  return parts;
};

interface ListItem {
  content: string;
  level: number;
  type: 'ordered' | 'unordered';
  index?: number;
  children: ListItem[];
}

// Helper function to build nested list structure
const buildNestedList = (items: { content: string; indent: number; type: 'ordered' | 'unordered'; index?: number }[]): ListItem[] => {
  const root: ListItem[] = [];
  const stack: ListItem[] = [];

  items.forEach(item => {
    const newItem: ListItem = {
      content: item.content,
      level: item.indent,
      type: item.type,
      index: item.index,
      children: []
    };

    while (stack.length > 0 && stack[stack.length - 1].level >= item.indent) {
      stack.pop();
    }

    if (stack.length === 0) {
      root.push(newItem);
    } else {
      stack[stack.length - 1].children.push(newItem);
    }

    stack.push(newItem);
  });

  return root;
};

// Render nested list items
const renderListItems = (items: ListItem[]): ReactNode => {
  return (
    <View>
      {items.map((item, index) => (
        <View key={index}>
          <View style={[styles.listItem, { marginLeft: item.level * 10 }]}>
            <Text style={styles.listMarker}>
              {item.type === 'ordered' ? `${item.index}.` : '•'}
            </Text>
            <Text style={styles.listItemContent}>
              {processInlineMarkdown(item.content)}
            </Text>
          </View>
          {item.children.length > 0 && renderListItems(item.children)}
        </View>
      ))}
    </View>
  );
};

// Convert markdown string to react-pdf elements
const parseMarkdown = (markdown: string): ReactNode[] => {
  const lines = markdown.split('\n');
  const elements: ReactNode[] = [];
  let listItems: { content: string; indent: number; type: 'ordered' | 'unordered'; index?: number }[] = [];
  let codeBlock: string[] = [];
  let isInCodeBlock = false;

  const flushListItems = () => {
    if (listItems.length > 0) {
      const nestedList = buildNestedList(listItems);
      elements.push(renderListItems(nestedList));
      listItems = [];
    }
  };

  const flushCodeBlock = () => {
    if (codeBlock.length > 0) {
      elements.push(
        <View key={elements.length} style={styles.codeBlock}>
          <Text>{codeBlock.join('\n')}</Text>
        </View>
      );
      codeBlock = [];
    }
  };

  lines.forEach((line, index) => {
    // Handle code blocks
    if (line.trim().startsWith('```')) {
      if (isInCodeBlock) {
        flushCodeBlock();
        isInCodeBlock = false;
      } else {
        flushListItems();
        isInCodeBlock = true;
      }
      return;
    }

    if (isInCodeBlock) {
      codeBlock.push(line);
      return;
    }

    // Skip empty lines
    if (!line.trim()) {
      flushListItems();
      return;
    }

    // Handle standalone images
    if (line.trim().startsWith('![')) {
      flushListItems();
      elements.push(
        <View key={elements.length}>
          {processInlineMarkdown(line.trim())}
        </View>
      );
      return;
    }

    // Headers
    if (line.trim().startsWith('#')) {
      flushListItems();
      const level = line.trim().match(/^#+/)?.[0].length || 1;
      const headerText = line.trim().replace(/^#+\s/, '');
      const style = level === 1 ? styles.heading1 
                 : level === 2 ? styles.heading2 
                 : styles.heading3;
      
      elements.push(
        <Text key={elements.length} style={style}>
          {processInlineMarkdown(headerText)}
        </Text>
      );
      return;
    }

    // Ordered list items
    const orderedListMatch = line.match(/^(\s*)(\d+)\.\s+(.+)$/);
    if (orderedListMatch) {
      const [, indent, number, content] = orderedListMatch;
      listItems.push({
        content: content.trim(),
        indent: indent.length,
        type: 'ordered',
        index: parseInt(number, 10)
      });
      return;
    }

    // Unordered list items
    const unorderedListMatch = line.match(/^(\s*)[*-]\s+(.+)$/);
    if (unorderedListMatch) {
      const [, indent, content] = unorderedListMatch;
      listItems.push({
        content: content.trim(),
        indent: indent.length,
        type: 'unordered'
      });
      return;
    }

    // Regular paragraphs
    flushListItems();
    elements.push(
      <Text key={elements.length} style={styles.paragraph}>
        {processInlineMarkdown(line.trim())}
      </Text>
    );
  });

  flushListItems();
  flushCodeBlock();
  return elements;
};

interface MarkdownPDFProps {
  content: string;
}

const MarkdownPDF = ({ content }: MarkdownPDFProps) => {
  const elements = parseMarkdown(content);
  return <>{elements}</>;
};

export { MarkdownPDF };
