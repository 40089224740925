import React from 'react';
import { ListItem, ListItemText, Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useIntl } from 'react-intl';
import { Conversation } from '../../types/conversation';

interface ConversationItemProps {
  conversation: Conversation;
  isSelected: boolean;
  onSelect: (conversation: Conversation) => void;
  onEdit: (id: number, title: string) => void;
  onDelete: (id: number) => void;
}

export const ConversationItem: React.FC<ConversationItemProps> = ({
  conversation,
  isSelected,
  onSelect,
  onEdit,
  onDelete
}) => {
  const intl = useIntl();

  return (
    <ListItem
      button
      selected={isSelected}
      onClick={() => onSelect(conversation)}
      secondaryAction={
        <Box sx={{ display: 'flex', gap: 1, ml: 1 }}>
          <IconButton
            edge="end"
            sx={{ borderRadius: '50%' }}
            onClick={(e) => {
              e.stopPropagation();
              const newTitle = prompt(intl.formatMessage({ id: 'general.Edit-Conversation' }), conversation.title);
              if (newTitle) {
                onEdit(conversation.id, newTitle);
              }
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            edge="end"
            sx={{ borderRadius: '50%' }}
            onClick={(e) => {
              e.stopPropagation();
              if (window.confirm(intl.formatMessage({ id: 'general.Delete-Conversation' }))) {
                onDelete(conversation.id);
              }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      }
      sx={{
        pr: 9, // Make room for action buttons
      }}
    >
      <ListItemText
        primary={conversation.title || 'Untitled'}
        secondary={new Date(conversation.updatedAt).toLocaleDateString()}
        primaryTypographyProps={{
          noWrap: true,
          style: { 
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }
        }}
      />
    </ListItem>
  );
};
