import React, { useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import TrelloIcon from "@mui/icons-material/ViewList";
import Tooltip from "@mui/material/Tooltip";
import { FormattedMessage } from "react-intl";
import TrelloCardsModal from "./TrelloCardsModal";
import { Release } from "./types";

const Row: React.FC<{
  release: Release & {
    handleOpenEdinModal: any;
    date: string;
  };
}> = ({ release }) => {
  const [trelloModalOpen, setTrelloModalOpen] = useState(false);
  const { id, status, handleOpenEdinModal, date, project } = release;

  const handleEditQuote = () => {
    handleOpenEdinModal(release);
  };

  const handleTrelloClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setTrelloModalOpen(true);
  };

  const renderStatus = () => {
    switch (status) {
      case "draft":
        return (
          <FormattedMessage id="general-status.draft" defaultMessage="draft" />
        );
      case "deployed":
        return (
          <FormattedMessage
            id="general-status.deployed"
            defaultMessage="deployed"
          />
        );
      case "toRedeploy":
        return (
          <FormattedMessage
            id="general-status.toRedeploy"
            defaultMessage="to redeploy"
          />
        );
      case "released":
        return (
          <FormattedMessage
            id="general-status.released"
            defaultMessage="released"
          />
        );
      case "canceled":
        return (
          <FormattedMessage
            id="general-status.canceled"
            defaultMessage="canceled"
          />
        );
      default:
        console.error("unexpected status: ", status);
        return (
          <FormattedMessage
            id="general-status.unexpected-status"
            defaultMessage="unexpected status"
          />
        );
    }
  };

  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
        }}
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          event.stopPropagation();
          event.nativeEvent.stopImmediatePropagation();
          event.nativeEvent.stopPropagation();
          handleEditQuote();
        }}
      >
        <TableCell component="th" scope="row">
          {id}
        </TableCell>
        <TableCell component="th" scope="row">
          {project?.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {date}
        </TableCell>
        <TableCell component="th" scope="row">
          {renderStatus()}
        </TableCell>
        <TableCell align="right">
          <Tooltip title={
            <FormattedMessage 
              id="release.trello-cards.button" 
              defaultMessage="View Trello Cards"
            />
          }>
            <IconButton
              onClick={handleTrelloClick}
              size="small"
            >
              <TrelloIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <TrelloCardsModal
        open={trelloModalOpen}
        onClose={() => setTrelloModalOpen(false)}
        release={release}
      />
    </>
  );
};

export default Row;
